import { Directive } from '@angular/core';

@Directive({
  selector: '[appCustomValidator]'
})
export class CustomValidatorDirective {

  constructor() { }

}
