import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'StateName' })
export class StateNamePipe implements PipeTransform {
    transform(value: number): string {
        switch (value) {
            case 1:
            case 4:
            case 6:
                return "เฝ้าระวัง";
            case 2:
                return "ไม่พบประวัติ";
            case 3:
            case 5:
                return "เข้าเกณฑ์";
            default:
                return "ไม่รู้สถานะ";
        }
    }
}