import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thaiBool'
})
export class ThaiBoolPipe implements PipeTransform {

  transform(value: boolean): string {
    if (value)
      return "ใช่";
    else
      return "ไม่";
  }

}
