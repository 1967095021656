import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { StartComponent } from './component/start/start.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SecondComponent } from './component/second/second.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ResultComponent } from './component/result/result.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { TelemedicComponent } from './component/telemedic/telemedic.component';
import { TelemedicResultComponent } from './component/telemedic-result/telemedic-result.component';
import { LayoutComponent } from './component/layout/layout.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSelectModule } from '@angular/material/select';
import { CustomValidatorDirective } from './service/custom-validator.directive';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';
import { ClinicComponent } from './clinic/clinic.component';
import { StartClinicComponent } from './clinic/component/start/start-clinic.component';
import { ScheduleComponent } from './clinic/component/schedule/schedule.component';
import { LoginComponent } from './clinic/component/login/login.component';
import { NavComponent } from './clinic/component/nav/nav.component';
import { UserProfileComponent } from './clinic/component/user-profile/user-profile.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SignupComponent } from './clinic/component/signup/signup.component';
import { ScheduleListComponent } from './clinic/component/auth/schedule-list/schedule-list.component';
import { AuthComponent } from './clinic/component/auth/auth.component';
import { LoadingSpinnerComponent } from './clinic/component/loading-spinner/loading-spinner.component';
import { ScheduleResultComponent } from './clinic/component/schedule-result/schedule-result.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { StateNamePipe } from './clinic/service/state.pipe';
import { AppointstatePipe } from './clinic/service/appointstate.pipe';
import { ClinicQrDownloadComponent } from './clinic/component/clinic-qr-download/clinic-qr-download.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { ResultDialogComponent } from './clinic/component/auth/result-dialog/result-dialog.component';
import { ThaiBoolPipe } from './clinic/service/thai-bool.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { EditScheduleComponent } from './clinic/component/auth/edit-schedule/edit-schedule.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SignupResultComponent } from './clinic/component/signup/signup-result/signup-result.component';
import { SignupJsonComponent } from './clinic/component/signup-json/signup-json.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatMenuModule } from '@angular/material/menu';
import { ConfirmDialogComponent } from './clinic/component/confirm-dialog/confirm-dialog.component';
import { DoctorListComponent } from './clinic/component/doctor-list/doctor-list.component';
import { DoctorEditDialogComponent } from './clinic/component/doctor-list/doctor-edit-dialog/doctor-edit-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    ClinicQrDownloadComponent,
    StartComponent,
    SecondComponent,
    ResultComponent,
    TelemedicComponent,
    TelemedicResultComponent,
    StateNamePipe,
    LayoutComponent,
    AppointstatePipe,
    ClinicComponent,
    StartClinicComponent,
    ScheduleComponent,
    DoctorEditDialogComponent,
    ConfirmDialogComponent,
    LoginComponent,
    EditScheduleComponent,
    SignupComponent,
    ScheduleListComponent,
    AuthComponent,
    LoadingSpinnerComponent,
    ScheduleResultComponent,
    ThaiBoolPipe,
    ResultDialogComponent,
    NavComponent,
    DoctorListComponent,
    SignupJsonComponent,
    SignupResultComponent,
    UserProfileComponent,
    CustomValidatorDirective
  ],
  imports: [
    BrowserModule,
    NgbModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatNativeDateModule,
    MatDividerModule,
    FormsModule,
    AppRoutingModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatMenuModule,
    MatTableExporterModule,
    LayoutModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatIconModule,
    MatDialogModule,
    ClipboardModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule
  ],
  providers: [ScreenTrackingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
