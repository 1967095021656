import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { CityService } from 'src/app/service/city.service';
import { AnswerService } from 'src/app/service/answer.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { ClinicService, ClinicInfo } from '../../service/clinic.service';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore/firestore';
import { AuthService } from '../../service/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public error = false;
  profileForm = new FormGroup({
    email: new FormControl(null, [Validators.required,]),
    password: new FormControl(null, [Validators.required,])
  });

  get email() { return this.profileForm.get('email'); }
  get password() { return this.profileForm.get('password'); }

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.authService.SignIn(this.email.value, this.password.value).then(() => {
      //routing
      this.router.navigate(['home'])
    }).catch(() => {
      this.error = true;
    })

  }
}
