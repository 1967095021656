<mat-dialog-content class="mat-typography">
    <div class="sticky-top" style="background-color: white;">
        <div class="row">
            <div class="col-6">
                <p class="contact">
                    เวลานัดหมาย:{{data.appointDate.day}}/{{data.appointDate.month}}/{{data.appointDate.year}}
                    {{data.appointTimeString}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <p>ชื่อ: {{data.name}} นามสกุล: {{data.surname}}</p>
                <p>เพศ: {{data.sex}}</p>
                <p>ID: {{data.idCard}}</p>
            </div>
            <div class="col-4">
                <p>วันเกิด: {{data.birthday | date}}</p>
                <p>อายุ: {{data.age}}</p>
                <p>HN: {{data.hm }}</p>
            </div>
            <div class="col-4">
                <p>เบอร์โทร: <span class="font-big">{{data.telphone}}</span></p>
                <p>Line ID: <span class="font-big">{{data.lineId}}</span></p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>Note: <span class="font-big">{{data.note}}</span></p>
            </div>
        </div>
        <mat-divider></mat-divider>
    </div>
    <div *ngIf="data.screenData &&data.screenData.resultId >0 ; else noAnswer">
        <h1 mat-dialog-title class="text-center">ผลการคัดกรอง</h1>
        <div class="text-center result">
            <div *ngIf="data.screenData.resultId == 0">กำลังประเมินความเสี่ยง</div>
            <div *ngIf="data.screenData.resultId == 1">
                คุณมีความเสี่ยงในการสัมผัสเชื้อโรค CORONA VIRUS 2019 <br />
                แต่ยังไม่มีความจำเป็นต้องมารพ.เพื่อตรวจหาโรค<br />
            </div>
            <div *ngIf="data.screenData.resultId == 2">
                คุณไม่มีความเสี่ยงในการได้รับเชื้อ COVID-19
            </div>
            <div *ngIf="data.screenData.resultId == 3">
                คุณอาจติดเชื้อ COVID-19<br />
                กรุณาป้องกันเชื้อโรค และมาพบแพทย์ที่โรงพยาบาล ตามสิทธิ์การรักษา
            </div>
            <div *ngIf="data.screenData.resultId == 4">
                คุณมีความเสี่ยงในการสัมผัสเชื้อโรค CORONA VIRUS 2019<br />
                แต่ยังไม่มีความจำเป็นต้องมารพ.เพื่อตรวจหาโรค<br />
                กรุณา สังเกตอาการ และ แยกตัวจากผู้อื่น
            </div>
            <div *ngIf="data.screenData.resultId == 5">
                คุณเป็นกลุ่มเสี่ยง อาจมีโอกาสสัมผัสเชื้อ COVID-19<br />
                กรุณาป้องกันเชื้อโรค และมาพบแพทย์ที่โรงพยาบาล ตามสิทธิ์การรักษา
            </div>
            <div *ngIf="data.screenData.resultId == 6">
                คุณเป็นกลุ่มเสี่ยง อาจมีโอกาสสัมผัสเชื้อ COVID-19<br />
                แต่ยังไม่มีความจำเป็นต้องมารพ.เพื่อตรวจหาโรค<br />
                กรุณา สังเกตอาการ และ แยกตัวจากผู้อื่น
            </div>
        </div>

        <div class="text-center" style="padding-top: 10px;">
            <h2><span class="badge badge-secondary badge-custom">ประวัติเสี่ยง</span></h2>
        </div>
        <p style="width: 100%;"></p>
        <div class="row line-top">
            <div class="col-6">
                1.ในช่วง 14 วันที่ผ่านมา ท่านได้มีการเดินทางกลับจากต่างประเทศหรือไม่
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q1 | thaiBool}}
            </div>
        </div>
        <div class="row">
            <div class="col-6 text-right">
                ประเทศ
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q1_detail}}
            </div>
        </div>
        <div class="row">
            <div class="col-6 text-right">
                วันที่เดินทางกลับ
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q1_date|date}}
            </div>
        </div>
        <div class="row  line-top">
            <div class="col-6">
                2.ในช่วง 14 วันท่านได้อาศัย หรือเดินทางมาจากพื้นที่ที่มีการรายงานการติดเชื้อหรือไม่
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q2 | thaiBool}}
            </div>
        </div>
        <div class="row">
            <div class="col-6 text-right">
                จังหวัด
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q2_city}}
            </div>
        </div>
        <div class="row">
            <div class="col-6 text-right">
                สถานที่
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q2_place}}
            </div>
        </div>
        <div class="row">
            <div class="col-6 text-right">
                วันที่
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q2_date|date}}
            </div>
        </div>
        <div class="row">
            <div class="col-6 text-right">
                ช่วงเวลา
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q2_Time}}
            </div>
        </div>
        <div class="row line-top">
            <div class="col-6">
                3.มีประวัติสัมผัสกับผู้ป่วยเข้าข่าย / ยืนยันโรคติดเชื้อไวรัส COVID-19
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q3 | thaiBool}}
            </div>
        </div>
        <div class="row">
            <div class="col-6 text-right">
                ความสัมพันธ์
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q3_relation}}:{{data.screenData.q3_detail}}
            </div>
        </div>
        <div class="row line-top">
            <div class="col-6">
                4.ท่านเป็นผู้ที่ประกอบอาชีพที่เกี่ยวข้องกับนักท่องเที่ยว สถานที่แออัด หรือติดต่อกับคนจำนวนมาก
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q4 | thaiBool}}
            </div>
        </div>
        <div class="row">
            <div class="col-6 text-right">
                ระบุอาชีพ
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q4_career}}:{{data.screenData.q4_other}}
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                5.ท่านเป็นบุคลากรทางการแพทย์หรือสาธารณสุข
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q5 | thaiBool}}
            </div>
        </div>
        <div class="row">
            <div class="col-6 text-right">
                ระบุอาชีพ
            </div>
            <div class="col-6 text-center answer">
                {{data.screenData.q5_medCareer}}:{{data.screenData.q5_other}}
            </div>
        </div>
        <div class="text-center" style="padding-top: 10px;">
            <h2><span class="badge badge-secondary badge-custom">อาการ</span></h2>
        </div>
        <div class="box-center">
            <div class="row">
                <div class="col-4">
                    <mat-icon style="color: red;" aria-hidden="false" *ngIf="data.screenData.isfever">done</mat-icon>
                    <mat-icon style="color: blue;" aria-hidden="false" *ngIf="!data.screenData.isfever">clear</mat-icon>
                </div>
                <div class="col-8">
                    ไข้ ({{data.screenData.temp}})
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <mat-icon style="color: red;" aria-hidden="false" *ngIf="data.screenData.isCough">done</mat-icon>
                    <mat-icon style="color: blue;" aria-hidden="false" *ngIf="!data.screenData.isCough">clear</mat-icon>
                </div>
                <div class="col-8">
                    ไอ
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <mat-icon style="color: red;" aria-hidden="false" *ngIf="data.screenData.isSnot">done</mat-icon>
                    <mat-icon style="color: blue;" aria-hidden="false" *ngIf="!data.screenData.isSnot">clear</mat-icon>
                </div>
                <div class="col-8">
                    น้ำมูก
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <mat-icon style="color: red;" aria-hidden="false" *ngIf="data.screenData.isSoreThroat">done
                    </mat-icon>
                    <mat-icon style="color: blue;" aria-hidden="false" *ngIf="!data.screenData.isSoreThroat">clear
                    </mat-icon>
                </div>
                <div class="col-8">
                    เจ็บคอ
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <mat-icon style="color: red;" aria-hidden="false" *ngIf="data.screenData.isGasp">done</mat-icon>
                    <mat-icon style="color: blue;" aria-hidden="false" *ngIf="!data.screenData.isGasp">clear</mat-icon>
                </div>
                <div class="col-8">
                    หายใจลำบาก หอบเหนื่อย
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-12 text-right">
                <div>วัน - เวลา การทำแบบสอบถาม</div>
                <div>{{data.screenData.datetimeMilisecond | date:'d-MM-yyyy ;  H:mm:ss'}}</div>
            </div>
        </div>

    </div>

    <ng-template #noAnswer>
        <div class="center" style="padding-top: 2rem;">
            <h1>คนไข้ไม่ได้ตอบแบบสอบถามประเมินความเสี่ยง</h1>
        </div>
    </ng-template>
    <div class="row">
        <div class="col-12 text-right">
            <button class="btn btn-light btn-sm" mat-dialog-close tabindex="-1">ยกเลิก</button>&nbsp;
            <button class="btn btn-danger btn-sm" *ngIf="data.appointState == 0" tabindex="-1" (click)="cancel()">
                ยกเลิกการนัด</button>
        </div>
    </div>
</mat-dialog-content>