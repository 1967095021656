import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AnswerService } from 'src/app/service/answer.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { ClinicService, ClinicInfo } from 'src/app/clinic/service/clinic.service';

@Component({
  selector: 'app-second',
  templateUrl: './second.component.html',
  styleUrls: ['./second.component.scss']
})
export class SecondComponent implements OnInit {
  public relations = ["บุคคลในครอบครัวที่อาศัยบ้านเดียวกัน", "เพื่อนร่วมงาน", "เพื่อนบ้าน", "อื่นๆ"]
  public careers = ["ธุรกิจสนามมวย", "สถานบันเทิง", "ร้านอาหาร", "พนักงานต้อนรับ", "โรงแรม", "พนักงานเสริฟ", "ค้าขาย", "อื่นๆ"]
  public medCareers = ["แพทย์", "ทันตแพทย์", "พยาบาล", "เภสัชกร", "ผู้ช่วยพยาบาล", "ผู้ช่วยทันตแพทย์", "เจ้าหน้าที่ห้องปฏิบัติการ", "อื่นๆ"]
  public riskCountries = ["สาธารณรัฐเกาหลี", "ไต้หวัน", "จีน", "มาเก๊า", "ฮ่องกง", "ญี่ปุ่น", 'มาเลเซีย', "เวียดนาม", "สิงคโปร์", "อิตาลี", "อิสลามอิหร่าน"]

  get q1() { return this.profileForm.get('q1'); }
  get q1_date() { return this.profileForm.get('q1_date'); }
  get q1_detail() { return this.profileForm.get('q1_detail'); }
  get q2() { return this.profileForm.get('q2'); }
  get q2_city() { return this.profileForm.get('q2_city'); }
  get q2_place() { return this.profileForm.get('q2_place'); }
  get q2_date() { return this.profileForm.get('q2_date'); }
  get q2_Time() { return this.profileForm.get('q2_Time'); }
  get q2_Other() { return this.profileForm.get('q2_Other'); }
  get q3() { return this.profileForm.get('q3'); }
  get q3_relation() { return this.profileForm.get('q3_relation'); }
  get q3_detail() { return this.profileForm.get('q3_detail'); }
  get q4() { return this.profileForm.get('q4'); }
  get q4_career() { return this.profileForm.get('q4_career'); }
  get q4_other() { return this.profileForm.get('q4_other'); }
  get q5() { return this.profileForm.get('q5'); }
  get q5_medCareer() { return this.profileForm.get('q5_medCareer'); }
  get q5_other() { return this.profileForm.get('q5_other'); }

  get isfever() { return this.profileForm.get('isfever'); }
  get temp() { return this.profileForm.get('temp'); }
  get isCough() { return this.profileForm.get('isCough'); }
  get isSnot() { return this.profileForm.get('isSnot'); }
  get isSoreThroat() { return this.profileForm.get('isSoreThroat'); }
  get isGasp() { return this.profileForm.get('isGasp'); }

  profileForm = new FormGroup({
    q1: new FormControl(null, [Validators.required,]),
    q1_date: new FormControl(null),
    q1_detail: new FormControl(null),
    q2: new FormControl(null, [Validators.required,]),
    q2_city: new FormControl(null),
    q2_place: new FormControl(null),
    q2_date: new FormControl(null),
    q2_Time: new FormControl(null),
    q2_Other: new FormControl(null),
    q3: new FormControl(null, [Validators.required,]),
    q3_relation: new FormControl(null),
    q3_detail: new FormControl(null),

    q4: new FormControl(null, [Validators.required,]),
    q4_career: new FormControl(null),
    q4_other: new FormControl(null),

    q5: new FormControl(null, [Validators.required,]),
    q5_medCareer: new FormControl(null),
    q5_other: new FormControl(null),

    isfever: new FormControl(null),
    temp: new FormControl(null),

    isCough: new FormControl(null),
    isSnot: new FormControl(null),
    isSoreThroat: new FormControl(null),
    isGasp: new FormControl(null),
  });
  isSmallScreen = false;
  layoutSub: Subscription;
  public clinicInfo: ClinicInfo;

  constructor(private router: Router,
    private answerService: AnswerService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private clinicService: ClinicService) { }

  ngOnInit(): void {

    this.clinicInfo = this.clinicService.getClinicInfo();

    const layoutChanges = this.breakpointObserver.observe([
      '(max-width: 768px)'
    ]);
    this.layoutSub = layoutChanges.subscribe(result => {
      this.isSmallScreen = result.matches;
    });
  }
  ngOnDestroy() {
    if (this.layoutSub)
      this.layoutSub.unsubscribe();
  }

  onSubmit() {

    this.answerService.q1(this.q1.value == 'y');
    this.answerService.q1_date(this.q1_date.value);
    this.answerService.q1_detail(this.q1_detail.value);

    this.answerService.q2(this.q2.value == 'y');
    this.answerService.q2_city(this.q2_city.value);
    this.answerService.q2_place(this.q2_place.value);
    this.answerService.q2_date(this.q2_date.value);
    this.answerService.q2_Time(this.q2_Time.value);
    this.answerService.q2_Other(this.q2_Other.value);

    this.answerService.q3(this.q3.value == 'y');
    this.answerService.q3_relation(this.q3_relation.value);
    this.answerService.q3_detail(this.q3_detail.value);

    this.answerService.q4(this.q4.value == 'y');
    this.answerService.q4_career(this.q4_career.value);
    this.answerService.q4_other(this.q4_other.value);

    this.answerService.q5(this.q5.value == 'y');
    this.answerService.q5_medCareer(this.q5_medCareer.value);
    this.answerService.q5_other(this.q5_other.value);

    this.answerService.isfever(this.isfever.value == true);
    this.answerService.temp(this.temp.value);
    this.answerService.isCough(this.isCough.value == true);
    this.answerService.isSnot(this.isSnot.value == true);
    this.answerService.isSoreThroat(this.isSoreThroat.value == true);
    this.answerService.isGasp(this.isGasp.value == true);

    if (this.clinicInfo)
      this.answerService.data.dentalUrl = this.clinicInfo.url;

    this.answerService.calcurate();

    this.answerService.save().then((value) => {
      this.answerService.data.id = value.id;
      this.router.navigate(['./../result'], { relativeTo: this.route });
    })

  }
}








