import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormGroupDirective, NgForm } from '@angular/forms';
import { CityService } from 'src/app/service/city.service';
import { AnswerService } from 'src/app/service/answer.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { ClinicService, ClinicInfo } from '../../service/clinic.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from '../../service/auth.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export function nameRealidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const pass = nameRe.test(control.value);
    return pass ? null : { 'nameRealidator': { value: control.value } };
  };
}

interface MyObj {
  name: string;
  url: string;
  city: string;
  area: string;
  telphone: string;
  website: string;
  line: string;
  email: string;
  doctorname: string;

}

class Error {
  email: string;
  message: string;
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-signup-json',
  templateUrl: './signup-json.component.html',
  styleUrls: ['./signup-json.component.scss']
})
export class SignupJsonComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
  public error = new Array<Error>();
  profileForm = new FormGroup({
    data: new FormControl(null, [Validators.required,]),
  });

  get data() { return this.profileForm.get('data'); }

  constructor(private authService: AuthService,
    private clinicService: ClinicService,
    private cityService: CityService) { }

  ngOnInit(): void {

  }

  showResult() {

    // this.modalService.open(SignupResultComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true });

  }



  async onSubmit() {
    if (this.profileForm.invalid == false) {
      const jsonData: MyObj[] = JSON.parse(this.data.value);

      for (let index = 0; index < jsonData.length; index++) {
        const element = jsonData[index];
        console.log(element)
        let createdUser: firebase.auth.UserCredential;
        try {
          const isExist = await this.clinicService.isClinicUrlExist(element.url);
          if (!isExist) {

            const clinicInfo: ClinicInfo = new ClinicInfo();
            clinicInfo.name = element.name;
            clinicInfo.url = element.url;
            clinicInfo.area = element.area;
            clinicInfo.city = element.city;
            clinicInfo.telphone = element.telphone;
            clinicInfo.LineId = element.line
            clinicInfo.email = element.email
            clinicInfo.website = element.website
            clinicInfo.doctorName = element.doctorname

            await this.clinicService.createClinicInfo(clinicInfo);
            this.clinicService.setClinicInfo(clinicInfo);
            createdUser = await this.authService.Register(element.email, element.telphone);

            await this.authService.createUserInfo(createdUser.user.uid, element.url);

            this.showResult();


          } else {
            const err = new Error();
            err.email = element.email;
            err.message = "มีคนใช้ Url นี้แล้ว โปรดเลือก Url ใหม่";
            this.error.push(err)
          }
        } catch (error) {
          console.error(error);
          if (createdUser) {
            this.authService.deleteUser(createdUser.user.uid);
            this.authService.deleteUserInfo(createdUser.user.uid);
          }
          this.clinicService.deleteClinicInfo(element.url);
          const err = new Error();
          err.email = element.email;
          err.message = "Internal Error";
          this.error.push(err)
        }
      }
      console.log("Done")
    }
  }
}
