import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';

export class ClinicInfo {
  name: string;
  url: string;
  area: string;
  city: string;
  telphone: string;
  LineId: string;
  email: string;
  website: string;
  doctorName: string;
  doctorList: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ClinicService {

  private clinicInfo: ClinicInfo;
  constructor(private afs: AngularFirestore) { }

  requestClinicInfo(url) {
    return this.afs.doc<ClinicInfo>('clinic/' + url).valueChanges();
  }

  addDoctor(url, doctorName) {
    const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
    return this.afs.doc('clinic/' + url).update({
      doctorList: arrayUnion(doctorName)
    });
  }

  removeDoctor(url, doctorName) {
    const arrayRemove = firebase.firestore.FieldValue.arrayRemove;
    return this.afs.doc('clinic/' + url).update({
      doctorList: arrayRemove(doctorName)
    });
  }

  saveDoctorList(url, doctorNames: string[]) {
    return this.afs.doc('clinic/' + url).update({
      doctorList: doctorNames
    });
  }

  setClinicInfo(clinicInfo) {
    return this.clinicInfo = clinicInfo;
  }

  getClinicInfo() {
    return this.clinicInfo;
  }

  async isClinicUrlExist(clinicUrl) {
    const result = await this.afs.doc<ClinicInfo>('clinic/' + clinicUrl).get().toPromise();
    return result.exists;
  }

  createClinicInfo(clinicInfo: ClinicInfo) {
    return this.afs.doc<ClinicInfo>('clinic/' + clinicInfo.url).set(JSON.parse(JSON.stringify(clinicInfo)), { merge: true });
  }

  deleteClinicInfo(clinicUrl: string) {
    return this.afs.doc<ClinicInfo>('clinic/' + clinicUrl).delete();
  }
}

