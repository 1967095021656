import { Component, OnInit } from '@angular/core';
import { ClinicInfo, ClinicService } from '../../service/clinic.service';
import { ScheduleService, Schedule } from '../../service/schedule.service';


@Component({
  selector: 'app-schedule-result',
  templateUrl: './schedule-result.component.html',
  styleUrls: ['./schedule-result.component.scss']
})
export class ScheduleResultComponent implements OnInit {
  public clinicInfo: ClinicInfo;
  public scheduleResult: Schedule;
  public dateString;
  private months = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
  private days = ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"];
  constructor(private scheduleService: ScheduleService, private clinicService: ClinicService) { }

  ngOnInit(): void {
    this.clinicInfo = this.clinicService.getClinicInfo();
    this.scheduleResult = this.scheduleService.getSchedule();
    if (this.scheduleResult) {
      const date = new Date(this.scheduleResult.appointDate.year, this.scheduleResult.appointDate.month - 1, this.scheduleResult.appointDate.day)
      this.dateString = this.days[date.getDay()] + " ที่ " + this.scheduleResult.appointDate.day + " " + this.months[date.getMonth()] + " " + this.scheduleResult.appointDate.year
    }

  }



}
