<div>
    <button type="button" class="btn btn-primary" (click)="openEditDialog()">เพิ่มหมอ</button>
    <table mat-table [dataSource]="dataSource">


        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element}} </td>
        </ng-container>


        <!-- Symbol Column -->
        <ng-container matColumnDef="button">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <button type="button" (click)="delete(element)" class="btn btn-danger">
                    Delete
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
</div>