import { Component, OnInit } from '@angular/core';
import { AnswerService } from 'src/app/service/answer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-telemedic-result',
  templateUrl: './telemedic-result.component.html',
  styleUrls: ['./telemedic-result.component.scss']
})
export class TelemedicResultComponent implements OnInit {
  public appointDay;
  public appointDate;
  public appointMonth;
  public appointYear;
  public appointTime;
  public appointHos;
  public appointRef;

  private months = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
  private days = ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"];
  private times = ["9:00 - 11:00", "11:00 - 13:00", "13:00 - 15:00", "15:00 - 17:00"]
  constructor(private answerService: AnswerService, private router: Router) { }

  ngOnInit(): void {
    if (this.answerService.appointData == null)
      this.router.navigate(['/telemedic']);

    const date = new Date(this.answerService.appointData.appointDate.year,
      this.answerService.appointData.appointDate.month - 1,
      this.answerService.appointData.appointDate.day);

    console.log(date.getDay());
    this.appointDay = this.days[date.getDay()]
    this.appointDate = this.answerService.appointData.appointDate.day;
    this.appointMonth = this.months[this.answerService.appointData.appointDate.month - 1]
    this.appointYear = this.answerService.appointData.appointDate.year;
    this.appointTime = this.times[this.answerService.appointData.appointTime]
    this.appointHos = this.answerService.appointData.hospital;
    this.appointRef = this.answerService.appointData.refId;
  }

}
