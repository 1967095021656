<div class="center">
    <div class="title-bar">
        <h1>Register</h1>
        <h2>Platform สำหรับคลินิกทันตกรรม</h2>
    </div>
    <div class="body" style="height: 100%;min-height: 100%;">
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{this.error}}
            </div>
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>ชื่อคลินิก</mat-label>
                <input [formControl]="clinicName" [errorStateMatcher]="matcher" matInput required>

                <mat-error *ngIf="clinicName.hasError('required')">
                    โปรดกรอก
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>URL</mat-label>
                <input [formControl]="url" [errorStateMatcher]="matcher" matInput required>

                <mat-error *ngIf="url.hasError('required')">
                    โปรดกรอก
                </mat-error>
                <mat-error *ngIf="url.hasError('nameRealidator')">
                    โปรดกรอกเฉพาะตัวหนังสือภาษาอังกฤษหรือตัวเลข โดยไม่เว้นช่องว่าง
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>จังหวัด</mat-label>
                <input type="text" matInput [formControl]="city" [errorStateMatcher]="matcher" [matAutocomplete]="auto"
                    required>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions| async" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="city.hasError('required')">
                    โปรดกรอก
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>อำเภอ</mat-label>
                <input type="text" matInput [formControl]="area" [errorStateMatcher]="matcher"
                    [matAutocomplete]="autoarea" required>
                <mat-autocomplete #autoarea="matAutocomplete">
                    <mat-option *ngFor="let option of filteredAreaOptions| async" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="area.hasError('required')">
                    โปรดกรอก
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>เบอร์โทรศัพท์</mat-label>
                <input [formControl]="telphone" [errorStateMatcher]="matcher" matInput required>

                <mat-error *ngIf="telphone.hasError('required')">
                    โปรดกรอก
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Line ID</mat-label>
                <input [formControl]="LineId" [errorStateMatcher]="matcher" matInput required>

                <mat-error *ngIf="LineId.hasError('required')">
                    โปรดกรอก
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>E-mail (ใช้ลงชื่อเข้าใช้ระบบ)</mat-label>
                <input type="email" [formControl]="email" [errorStateMatcher]="matcher" matInput required>

                <mat-error *ngIf="email.hasError('required')">
                    โปรดกรอก
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Password (ใช้ลงชื่อเข้าใช้ระบบ)</mat-label>
                <input [type]="hide ? 'password' : 'text'" [formControl]="password" [errorStateMatcher]="matcher"
                    matInput required>
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-hint>password is 8-20 characters</mat-hint>
                <mat-error *ngIf="password.hasError('required')">
                    โปรดกรอก
                </mat-error>
                <mat-error *ngIf="password.hasError('minLength') || password.hasError('maxLength') ">
                    password is 8-20 characters
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>เวปไซด์ (ถ้ามี)</mat-label>
                <input [formControl]="website" [errorStateMatcher]="matcher" matInput>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>ทันตแพทย์เจ้าของคลินิก</mat-label>
                <input [formControl]="doctorName" [errorStateMatcher]="matcher" matInput required>

                <mat-error *ngIf="doctorName.hasError('required')">
                    โปรดกรอก
                </mat-error>
            </mat-form-field>

            <p>
                <button type="submit" class="btn btn-primary btn-lg">ลงทะเบียน</button>
            </p>

        </form>
    </div>
</div>