import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appointstate'
})
export class AppointstatePipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case 0:
        return "นัด";
      case 1:
        return "ยกเลิกนัด";
      default:
        return "ไม่รู้สถานะ";
    }
  }

}
