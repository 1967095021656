import { Component, OnInit, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { ClinicQrDownloadComponent } from '../clinic-qr-download/clinic-qr-download.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @Input() hosName: string;
  constructor(
    public auth: AngularFireAuth,
    public dialog: MatDialog,
    private router: Router) { }

  ngOnInit(): void {
  }
  Logout() {
    this.auth.signOut().then(() => this.router.navigate(['/login']));
  }

  openUserProfileDialog(content) {
    const dialogRef = this.dialog.open(UserProfileComponent, {
      width: "1200px",
    });

  }
  closeResult = '';

  openQRCodeLoadingDialog() {

    const dialogRef = this.dialog.open(ClinicQrDownloadComponent, {
      width: "1200px",
    });

  }


}
