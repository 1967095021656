<div class="center">
    <div class="container-fluid title-bar" *ngIf="clinicInfo == null">
        <h1>ผลการประเมิน</h1>
        <h2>ความเสี่ยงการติดเชื้อ COVID-19</h2>
        <p>ผลการประเมินของท่าน จะถูกรวบรวมไปยังสถานพยาบาลในเขตที่ท่านได้เลือกไว้</p>
        <p>เพื่อเป็นประโยชน์สำหรับการดูแลและป้องกันเชิงรุกต่อไป</p>
    </div>
    <div class="container-fluid title-bar" *ngIf="clinicInfo != null">
        <h1>ผลการประเมิน</h1>
        <h2>ความเสี่ยงการติดเชื้อ COVID-19</h2>
        <p>ผลการประเมินของท่าน</p>
        <p>จะถูกนำไปใช้ในการวางแผนการรักษา</p>
        <p>โดยทันตแพทย์ประจำคลินิก</p>
    </div>
    <div class="container-fluid">
        <div class="result">
            <div *ngIf="resultId == 0">กำลังประเมินความเสี่ยง</div>
            <div *ngIf="resultId == 1">
                <p class="orange">คุณมีความเสี่ยงในการสัมผัสเชื้อโรค CORONA VIRUS 2019</p>
                <p>แต่ยังไม่มีความจำเป็นต้องมารพ.เพื่อตรวจหาโรค</p>
                <p>กรุณา สังเกตอาการ และ แยกตัวจากผู้อื่น</p>
            </div>
            <div *ngIf="resultId == 2">
                <p class="green">คุณไม่มีความเสี่ยงในการได้รับเชื้อ COVID-19</p>
                <p>คุณอาจเป็นไข้หวัด หรือไข้หวัดใหญ่</p>
            </div>
            <div *ngIf="resultId == 3">
                <p class="red">คุณอาจติดเชื้อ COVID-19</p>
                <p>กรุณาป้องกันเชื้อโรค และมาพบแพทย์ที่โรงพยาบาล ตามสิทธิ์การรักษา</p>
            </div>
            <div *ngIf="resultId == 4">
                <p class="orange">คุณมีความเสี่ยงในการสัมผัสเชื้อโรค CORONA VIRUS 2019</p>
                <p>แต่ยังไม่มีความจำเป็นต้องมารพ.เพื่อตรวจหาโรค</p>
                <p>กรุณา สังเกตอาการ และ แยกตัวจากผู้อื่น</p>
            </div>
            <div *ngIf="resultId == 5">
                <p class="orange">คุณเป็นกลุ่มเสี่ยง อาจมีโอกาสสัมผัสเชื้อ COVID-19</p>
                <p>กรุณาป้องกันเชื้อโรค และมาพบแพทย์ที่โรงพยาบาล ตามสิทธิ์การรักษา</p>
            </div>
            <div *ngIf="resultId == 6">
                <p class="orange">คุณเป็นกลุ่มเสี่ยง อาจมีโอกาสสัมผัสเชื้อ COVID-19</p>
                <p>แต่ยังไม่มีความจำเป็นต้องมารพ.เพื่อตรวจหาโรค</p>
                <p>กรุณา สังเกตอาการ และ แยกตัวจากผู้อื่น</p>
            </div>
        </div>
        <div class="form-group">
            <p *ngIf="clinicInfo == null"><a type="button" class="btn btn-warning btn-lg"
                    [routerLink]="['/telemedic']">จองคิวปรึกษา</a></p>
            <p *ngIf="clinicInfo"><a type="button" class="btn btn-warning btn-lg" routerLink="./../schedule">จองคิว
                    online ก่อนมาคลินิก</a></p>
            <p><a type="button" class="btn btn-outline-warning btn-lg" routerLink="./../">กลับหน้าหลัก</a></p>
        </div>
    </div>
</div>