<div class="center">
    <div class="container-fluid title-bar">
        <h1>แบบประเมินความเสี่ยงการติดเชื้อ COVID-19 </h1>
        <h2>ก่อนมาโรงพยาบาล</h2>
    </div>
    <div class="body container-fluid">
        <h3>เลือกพื้นที่ที่ท่านอาศัยอยู่ ณ ขณะนี้</h3>


        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <p>
                <ng-select [items]="cities" [formControl]="selectedCity" (change)="onChange($event)"
                    placeholder="จังหวัด">
                </ng-select>
            </p>
            <p>

                <ng-select [items]="areas" [formControl]="selectedArea" placeholder="อำเภอ"
                    (change)="onAreaChange($event)">
                </ng-select>
            </p>
            <p *ngIf="townships">

                <ng-select [items]="townships" [formControl]="selectedTownship" placeholder="ตำบล">
                </ng-select>
            </p>
            <p>
                <button type="submit" class="btn btn-primary btn-lg"
                    [disabled]="!profileForm.valid">เริ่มทำแบบสอบถาม</button>
            </p>

        </form>
    </div>

</div>