<div class="center">
    <div class="container-fluid title-bar">
        <h1>แบบประเมินความเสี่ยงการติดเชื้อ COVID-19 </h1>
        <h2 *ngIf="clinicInfo == null">ก่อนมาโรงพยาบาล</h2>
        <h2 *ngIf="clinicInfo != null">ก่อนทำการรักษาทางทันตกรรม</h2>
        <p>เพื่อประโยชน์สูงสุดของท่าน กรุณาให้ข้อมูลตามความเป็นจริง</p>
        <p>(แบบสอบถามประเมินความเสี่ยงชุดนี้จะเก็บข้อมูลโดยไม่ระบุตัวตน)</p>
    </div>
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">

        <div class="container-sm">
            <h4>
                <p class="badge badge-secondary badge-custom">ประวัติเสี่ยงต่อการติดเชื้อของท่าน ในช่วง 14
                    วันที่ผ่านมา</p>
            </h4>
            <div class="row">
                <div class="col-12">
                    1.ในช่วง 14 วันที่ผ่านมา ท่านได้มีการเดินทางกลับจากต่างประเทศหรือไม่ <span class="red">*</span>
                </div>
            </div>
            <mat-radio-group [formControl]="q1">
                <div class="row">
                    <div class="col-2 col-md-1" style="padding-top: 15px;">
                        <mat-radio-button name="q1" value="y">ใช่</mat-radio-button>
                    </div>
                    <div class="col-8 col-md-3">

                        <ng-select [items]="riskCountries" [formControl]="q1_detail" placeholder="ระบุประเทศ">
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2 col-md-1"></div>
                    <div class="col-10 col-md-11">
                        <mat-form-field class="example-full-width">
                            <input matInput [matDatepicker]="picker" placeholder="วันที่เดินทางกลับ"
                                [formControl]="q1_date">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker [touchUi]="isSmallScreen" #picker></mat-datepicker>
                        </mat-form-field>

                    </div>
                </div>
                <div class="row">
                    <div class="col-2 col-md-1">
                        <mat-radio-button name="q1" value="n">ไม่ใช่</mat-radio-button>
                    </div>

                </div>
            </mat-radio-group>
            <div class="row">
                <div class="col-12">
                    2.ในช่วง 14 วันท่านได้อาศัย หรือเดินทางมาจากพื้นที่ที่มีการรายงานการติดเชื้อหรือไม่ <span
                        class="red">*</span>
                </div>
            </div>
            <mat-radio-group [formControl]="q2">
                <div class="row">
                    <div class="col-2 col-md-1" style="padding-top: 15px;">
                        <mat-radio-button name="q2" value="y">ใช่</mat-radio-button>
                    </div>
                    <div class="col-10 col-md-11">
                        <p>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="จังหวัด" [formControl]="q2_city">
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="สถานที่" [formControl]="q2_place">
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field class="example-full-width">
                                <input matInput [matDatepicker]="picker2" placeholder="วันที่" [formControl]="q2_date">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker [touchUi]="isSmallScreen" #picker2></mat-datepicker>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="ช่วงเวลา" [formControl]="q2_Time">
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="อื่นๆ" [formControl]="q2_Other">
                            </mat-form-field>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2 col-md-1">
                        <mat-radio-button name="q2" value="n">ไม่ใช่</mat-radio-button>
                    </div>
                </div>
            </mat-radio-group>
            <div class="row">
                <div class="col-12">
                    3.มีประวัติสัมผัสกับผู้ป่วยเข้าข่าย / ยืนยันโรคติดเชื้อไวรัส COVID-19 <span class="red">*</span>
                </div>
            </div>
            <mat-radio-group [formControl]="q3">

                <div class="row">
                    <div class="col-2 col-md-1" style="padding-top: 15px;">
                        <mat-radio-button name="q3" value="y">ใช่</mat-radio-button>
                    </div>
                    <div class="col-8 col-md-4">
                        <p>
                            <ng-select [items]="relations" placeholder="ความสัมพันธ์" [formControl]="q3_relation">
                            </ng-select>
                        </p>
                        <p>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="ระบุความสัมพันธ์" [formControl]="q3_detail">
                            </mat-form-field>
                        </p>

                    </div>
                </div>
                <div class="row">
                    <div class="col-2 col-md-1">
                        <mat-radio-button name="q3" value="n">ไม่ใช่</mat-radio-button>
                    </div>
                </div>
            </mat-radio-group>
            <div class="row">
                <div class="col-12">
                    4.ท่านเป็นผู้ที่ประกอบอาชีพที่เกี่ยวข้องกับนักท่องเที่ยว สถานที่แออัด
                    หรือติดต่อกับคนจำนวนมาก <span class="red">*</span>
                </div>
            </div>
            <mat-radio-group [formControl]="q4">

                <div class="row">
                    <div class="col-2 col-md-1" style="padding-top: 15px;">
                        <mat-radio-button name="q4" value="y">ใช่</mat-radio-button>
                    </div>
                    <div class="col-8 col-md-3">
                        <p>
                            <ng-select [items]="careers" placeholder="อาชีพ" [formControl]="q4_career">
                            </ng-select>
                        </p>
                        <p>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="ระบุ อื่นๆ" [formControl]="q4_other">
                            </mat-form-field>
                        </p>

                    </div>
                </div>

                <div class="row">
                    <div class="col-2 col-md-1">
                        <mat-radio-button name="q4" value="n">ไม่ใช่</mat-radio-button>
                    </div>
                </div>
            </mat-radio-group>
            <div class="row">
                <div class="col-12">
                    5.ท่านเป็นบุคลากรทางการแพทย์หรือสาธารณสุข <span class="red">*</span>
                </div>
            </div>
            <mat-radio-group [formControl]="q5">

                <div class="row">
                    <div class="col-2 col-md-1" style="padding-top: 15px;">
                        <mat-radio-button name="q5" value="y">ใช่</mat-radio-button>
                    </div>
                    <div class="col-8 col-md-3">
                        <p>
                            <ng-select [items]="medCareers" placeholder="อาชีพ" [formControl]="q5_medCareer">
                            </ng-select>
                        </p>
                        <p>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="อื่นๆระบุ" [formControl]="q5_other">
                            </mat-form-field>
                        </p>

                    </div>
                </div>
                <div class="row">
                    <div class="col-2 col-md-1">
                        <mat-radio-button name="q5" value="n">ไม่ใช่</mat-radio-button>
                    </div>
                </div>
            </mat-radio-group>

            <div class="row">
                <div class="col-12">
                    <h4>
                        <p class="badge badge-secondary badge-custom">ท่านมีอาการดังต่อไปนี้หรือไม่</p>
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-2 col-md-1 cb">
                    <mat-checkbox [formControl]="isfever">ไข้</mat-checkbox>
                </div>
                <div class="col-4">
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="อุณหภูมิ" [formControl]="temp">
                    </mat-form-field>
                </div>

            </div>
            <div class="row">
                <div class="col-12">
                    <p>
                        <mat-checkbox [formControl]="isCough">ไอ</mat-checkbox>
                    </p>
                    <p>
                        <mat-checkbox [formControl]="isSnot">น้ำมูก</mat-checkbox>
                    </p>
                    <p>
                        <mat-checkbox [formControl]="isSoreThroat">เจ็บคอ</mat-checkbox>
                    </p>
                    <p>
                        <mat-checkbox [formControl]="isGasp">หายใจลำบาก หอบเหนื่อย</mat-checkbox>
                    </p>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button type="submit" class="btn btn-primary btn-lg"
                    [disabled]="!profileForm.valid">ทำการประเมิน</button>
            </div>
        </div>
    </form>
</div>