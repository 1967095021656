import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Data } from 'src/app/service/answer.service';


export class Schedule {
  id: string
  name: string;
  surname: string;
  sex: string;
  birthday: {
    day: number, month: number, year: number
  };
  age: number;
  hm: string;
  idCard: string;
  telphone: string;
  lineId: string;
  createdDate: number;
  appointDate: {
    day: number, month: number, year: number
  };
  appointDateMilli: number;
  appointTime: { hour: number, minute: number };
  appointTimeString: string;
  appointState: number;
  screenData: Data;
  note: string;
  doctorPick: string;
  refId: string;
}

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  private schedule: Schedule;
  constructor(private afs: AngularFirestore) { }

  getIdIfNew(schedule) {
    if (!schedule.id) {
      schedule.refId = (new Date().getTime() + Math.floor((Math.random() * 100) + 1)).toString();
      schedule.id = this.afs.createId();
    }
    return schedule;
  }

  set(schedule: Schedule, clinicUrl: string) {

    this.schedule = schedule;
    return this.afs.doc('clinic-schedule/' + clinicUrl + '/schedule/' + schedule.id).set(JSON.parse(JSON.stringify(schedule)), { merge: true });
  }


  getSchedules(clinicUrl: string, from: number, to: number) {
    return this.afs.collection<Schedule>('clinic-schedule/' + clinicUrl + '/schedule', ref => {
      return ref.where('appointDateMilli', '>=', from).where('appointDateMilli', '<=', to)
    }).valueChanges({ idField: 'id' });
  }

  cancelSchedule(id: string, clinicUrl: string) {
    return this.afs.doc('clinic-schedule/' + clinicUrl + '/schedule/' + id).update({ appointState: 1 })
  }

  getSchedule() {
    return this.schedule;
  }
}

