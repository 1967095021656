import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { CityService } from 'src/app/service/city.service';
import { AnswerService } from 'src/app/service/answer.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  profileForm = new FormGroup({
    selectedCity: new FormControl(null, [Validators.required,]),
    selectedArea: new FormControl(null, [Validators.required,]),
    selectedTownship: new FormControl()
  });

  cities;
  areas;
  townships;
  constructor(private answerService: AnswerService, private cityService: CityService, private router: Router, private auth: AngularFireAuth) { }

  ngOnInit() {
    this.cities = this.cityService.getCities();
  }

  get selectedCity() { return this.profileForm.get('selectedCity'); }
  get selectedArea() { return this.profileForm.get('selectedArea'); }
  get selectedTownship() { return this.profileForm.get('selectedTownship'); }

  onChange($event) {
    console.log('change' + $event);
    this.selectedArea.setValue(null);
    this.selectedTownship.setValue(null);
    this.townships = null;
    this.areas = this.cityService.getAreas($event);
  }

  onAreaChange($event) {
    console.log('change' + $event);
    this.selectedTownship.setValidators(null);
    this.selectedTownship.setValue(null);
    this.townships = this.cityService.getTownships(this.selectedCity.value, $event);
    if (this.townships)
      this.selectedTownship.setValidators(Validators.required);
    this.selectedTownship.updateValueAndValidity();
  }

  onSubmit() {
    this.answerService.area(this.selectedArea.value);
    this.answerService.city(this.selectedCity.value);
    if (this.selectedTownship.value)
      this.answerService.townShip(this.selectedTownship.value);
    this.answerService.first = true;
    this.router.navigate(['question']);
  }
}
