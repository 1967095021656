import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-doctor-edit-dialog',
    templateUrl: './doctor-edit-dialog.component.html',
    styleUrls: ['./doctor-edit-dialog.component.css']
})
export class DoctorEditDialogComponent implements OnInit {
    name = new FormControl(null);

    constructor(public dialogRef: MatDialogRef<DoctorEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
        // Update view with given values
        //    this.title = data.title;
        //   this.message = data.message;
    }

    ngOnInit() {
    }

    onConfirm(): void {
        // Close the dialog, return true
        this.dialogRef.close(this.name.value);
    }

    onDismiss(): void {
        // Close the dialog, return false
        this.dialogRef.close(null);
    }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {

    constructor(public title: string, public message: string) {
    }
}
