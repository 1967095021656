<div class="center">
    <div class="title-bar">
        <h1>COVID-19</h1>
        <h2>Platform สำหรับคลินิกทันตกรรม</h2>
    </div>
    <div class="body" style="height: 100%;min-height: 100%;">
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                Wrong Email and password
            </div>
            <p>
                <input type="email" class="form-control" placeholder="Email" [formControl]="email" aria-label="email">
            </p>
            <p>
                <input type="password" class="form-control" placeholder="Password" [formControl]="password"
                    aria-label="password">
            </p>
            <p>
                <button type="submit" class="btn btn-primary btn-lg" [disabled]="!profileForm.valid">Login</button>
            </p>

        </form>
    </div>
</div>