import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup, Validators } from '@angular/forms';
import { ScheduleService, Schedule } from 'src/app/clinic/service/schedule.service';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ClinicInfo, ClinicService } from 'src/app/clinic/service/clinic.service';
import { Subscription, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { startWith, map } from 'rxjs/operators';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-edit-schedule',
  templateUrl: './edit-schedule.component.html',
  styleUrls: ['./edit-schedule.component.scss']
})
export class EditScheduleComponent implements OnInit {

  profileForm = new FormGroup({
    name: new FormControl(null, [Validators.required,]),
    surname: new FormControl(),
    sex: new FormControl(),
    birthday: new FormControl(),
    age: new FormControl(),
    hm: new FormControl(),
    idCard: new FormControl(),
    telphone: new FormControl(null, [Validators.required,]),
    lineId: new FormControl(),
    appointDate: new FormControl(),
    appointTime: new FormControl({
      "hour": 8,
      "minute": 30
    }),
    note: new FormControl(),
    doctorPick: new FormControl()
  });

  matcher = new MyErrorStateMatcher();

  get name() { return this.profileForm.get('name'); }
  get surname() { return this.profileForm.get('surname'); }
  get sex() { return this.profileForm.get('sex'); }
  get birthday() { return this.profileForm.get('birthday'); }
  get age() { return this.profileForm.get('age'); }
  get hm() { return this.profileForm.get('hm'); }
  get idCard() { return this.profileForm.get('idCard'); }
  get telphone() { return this.profileForm.get('telphone'); }
  get lineId() { return this.profileForm.get('lineId'); }
  get appointDate() { return this.profileForm.get('appointDate'); }
  get appointTime() { return this.profileForm.get('appointTime'); }
  get note() { return this.profileForm.get('note'); }
  get doctorPick() { return this.profileForm.get('doctorPick'); }

  public clinicInfo: ClinicInfo;
  isSmallScreen = false;
  layoutSub: Subscription;

  filteredOptions: Observable<string[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Schedule,
    public dialogRef: MatDialogRef<EditScheduleComponent>,
    private clinicService: ClinicService,
    private snackBar: MatSnackBar,
    private scheduleService: ScheduleService,
    private breakpointObserver: BreakpointObserver,
    private calendar: NgbCalendar) { }

  ngOnInit(): void {
    console.log(this.data)
    this.clinicInfo = this.clinicService.getClinicInfo();
    this.appointDate.setValue(this.calendar.getToday());

    if (this.data) {
      this.name.setValue(this.data.name);
      this.surname.setValue(this.data.surname);
      this.sex.setValue(this.data.sex);
      this.birthday.setValue(this.data.birthday);
      this.age.setValue(this.data.age);
      this.hm.setValue(this.data.hm);
      this.idCard.setValue(this.data.idCard);
      this.telphone.setValue(this.data.telphone);
      this.lineId.setValue(this.data.lineId);
      this.appointDate.setValue(this.data.appointDate);
      this.appointTime.setValue(this.data.appointTime);
      this.note.setValue(this.data.note);
      this.doctorPick.setValue(this.data.doctorPick);
    }

    this.filteredOptions = this.doctorPick.valueChanges
      .pipe(
        startWith(''),
        map(value => value ? this._doctorListfilter(value) : this.clinicInfo.doctorList.slice())
      );


    const layoutChanges = this.breakpointObserver.observe([
      '(max-width: 768px)'
    ]);
    this.layoutSub = layoutChanges.subscribe(result => {
      this.isSmallScreen = result.matches;
    });
  }

  private _doctorListfilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    console.log(filterValue)
    return this.clinicInfo.doctorList.filter(option => option.toLowerCase().includes(filterValue));
  }
  /*markAsCall(element) {
    this.appointmentService.markAsCalled(element.id).then(() => {
      this.snackBar.open(`ติดต่อ ${element.refId} แล้ว`, '', { duration: 3000, });
      this.data.appointState = 1;
    }
    );
  }
  markAsUnCall(element) {
    this.appointmentService.markAsUnCalled(element.id).then(() => {
      this.snackBar.open(`ยกเลิกการติดต่อ ${element.refId} แล้ว`, '', { duration: 3000, })
      this.data.appointState = 0;
    });
  }
*/
  submitted: boolean = false;
  onSubmit() {
    console.log('submit');


    if (this.profileForm.valid) {
      this.submitted = true;
      this.snackBar.open('Saving...', '', {
        duration: 5000
      });

      const appointDate = this.appointDate.value;
      const date = new Date(appointDate.year, appointDate.month - 1, appointDate.day);

      let data: Schedule = new Schedule();
      if (this.data) {
        data.id = this.data.id;
        data.refId = this.data.refId;
      }
      data.name = this.name.value;
      data.surname = this.surname.value;
      data.sex = this.sex.value;
      data.birthday = this.birthday.value;
      data.age = this.age.value;
      data.hm = this.hm.value;
      data.idCard = this.idCard.value;
      data.telphone = this.telphone.value;
      data.lineId = this.lineId.value;
      data.createdDate = new Date().getTime();
      data.appointDate = this.appointDate.value;
      data.appointDateMilli = date.getTime();
      data.appointTime = this.appointTime.value;
      data.appointTimeString = data.appointTime.hour + ":" + data.appointTime.minute;
      data.appointState = 0;
      data.note = this.note.value;
      data.doctorPick = this.doctorPick.value;
      data = this.scheduleService.getIdIfNew(data);
      this.scheduleService.set(data, this.clinicInfo.url).then(() => this.saveSuccess(data))

    }

  }

  saveSuccess(data) {
    console.log(data)
    this.data = data;
    this.snackBar.open('Save Complete: ' + this.data.refId, '', {
      duration: 5000
    });
  }

}
