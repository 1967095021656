import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase';

@Component({
  selector: 'app-signup-result',
  templateUrl: './signup-result.component.html',
  styleUrls: ['./signup-result.component.scss']
})
export class SignupResultComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {

  }




}
