import { Component, OnInit, Input } from '@angular/core';

import { ClinicService, ClinicInfo } from '../../service/clinic.service';
import { QrService } from '../../service/qr.service';
import * as svg from 'save-svg-as-png';
@Component({
  selector: 'app-clinic-qr-download',
  templateUrl: './clinic-qr-download.component.html',
  styleUrls: ['./clinic-qr-download.component.scss']
})
export class ClinicQrDownloadComponent implements OnInit {
  clinicInfo: ClinicInfo;
  public qrCodeUri;
  public qrCodeScheduleUri;
  public nameSize = 30;
  public screenUrl;
  public scheduleUrl;
  @Input() showLogin = false;

  constructor(private clinicService: ClinicService, private qrService: QrService) { }

  async ngOnInit(): Promise<void> {
    this.clinicInfo = this.clinicService.getClinicInfo();
    console.log(this.clinicInfo.name.length)
    if (this.clinicInfo.name.length > 30) {
      this.nameSize = 20;
    } else if (this.clinicInfo.name.length > 25) {
      this.nameSize = 25;
    }
    this.screenUrl = 'https://www.ติดไม่ติด.com/dental/' + this.clinicInfo.url + "/question"
    this.scheduleUrl = 'https://www.ติดไม่ติด.com/dental/' + this.clinicInfo.url + "/schedule"

    this.qrCodeUri = await this.qrService.getQRCodeUri(this.screenUrl);
    this.qrCodeScheduleUri = await this.qrService.getQRCodeUri(this.scheduleUrl);

  }

  downloadMyFile(id, filename) {
    svg.svgAsPngUri(document.getElementById(id), {}, (uri) => {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', uri);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });

  }



}
