import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import QRCode from 'qrcode'

export class Summery {
  cleanDocs: number;
  numberOfDocs: number;
  standardizeDocs: number;
  watchOutDocs: number;
}

@Injectable({
  providedIn: 'root'
})
export class QrService {

  constructor(private afs: AngularFirestore) { }

  getQRCodeUri(url) {
    return QRCode.toDataURL(url)

  }

}

