<nav class="navbar navbar-light navbar-expand-md bg-faded justify-content-center">
    <a href="/" class="navbar-brand d-flex w-50 mr-auto">HM<span class="stop">Stop</span>Covid<span
            style="font-size: small;padding-top: 10px;padding-left: 5px;">v1.0.2</span></a>

    <div class="d-flex w-100" id="collapsingNavbar3">
        <ul class="navbar-nav w-100 justify-content-center">
            <li class="nav-item ">
                <span class="navbar-text justify-content-center">
                    {{hosName}}
                </span>

            </li>

        </ul>
        <ul class="nav navbar-nav ml-auto w-100 justify-content-end">
            <li class="nav-item text-right">
                <button class="btn btn-primary my-2 my-sm-0" (click)="openQRCodeLoadingDialog()" type="button">Download
                    QR code</button>
            </li>&nbsp;
            <li class="nav-item text-right" *ngIf="auth.user | async">
                <button class="btn btn-outline-primary my-2 my-sm-0" (click)="openUserProfileDialog(profile_content)"
                    type="button">User
                    Profile</button>
            </li>&nbsp;
            <li class="nav-item text-right" *ngIf="auth.user | async">
                <button class="btn btn-outline-danger my-2 my-sm-0" (click)="Logout()" type="button">Logout</button>
            </li>

        </ul>
    </div>
</nav>




<ng-template #profile_content let-modal>
    <app-user-profile></app-user-profile>
</ng-template>