<nav class="navbar navbar-expand-lg navbar-light">
    <a class="navbar-brand" href="/">HM<span class="stop">Stop</span>Covid</a>

    <ul class="navbar-nav mr-auto">
        <li class="nav-item">
            <div ngbDropdown class="d-inline-block">
                <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle> สำหรับคลินิกทันตกรรม
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <a ngbDropdownItem routerLink="/login">ลงชื่อเข้าใช้</a>
                    <a ngbDropdownItem routerLink="/signup">ลงทะเบียน</a>
                </div>
            </div>
        </li>

    </ul>
    <span class="navbar-text">
        Copyright © 2020 Merge Enterprise Co.,LTD. All rights reserved.
    </span>

</nav>

<router-outlet (activate)="onActivate($event)"></router-outlet>