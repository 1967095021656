import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { CityService } from 'src/app/service/city.service';
import { AnswerService } from 'src/app/service/answer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { ClinicService } from './service/clinic.service';


@Component({
  selector: 'app-clinic',
  templateUrl: './clinic.component.html',
  styleUrls: ['./clinic.component.scss']
})
export class ClinicComponent implements OnInit {

  private clinicInfoSub;
  public clinicInfo;
  constructor(private route: ActivatedRoute, private clinicService: ClinicService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.clinicInfoSub = this.clinicService.requestClinicInfo(params.get('clinicUrl')).subscribe((value) => {
        this.clinicInfo = value;
        this.clinicService.setClinicInfo(this.clinicInfo);
      })
    });
  }

}
