<h2 *ngIf="!this.data" mat-dialog-title>สร้างนัดใหม่</h2>
<h2 *ngIf="this.data" mat-dialog-title>แก้ไขนัด {{this.data.refId}}</h2>
<form [formGroup]="profileForm" (ngSubmit)="onSubmit()" id='telebody'>
    <mat-dialog-content class="mat-typography center">
        <div class="container-fluid">

            <div class="card">
                <div class="card-body">
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>ชื่อ</mat-label>
                            <input [formControl]="name" [errorStateMatcher]="matcher" matInput>

                            <mat-error *ngIf="name.hasError('required')">
                                โปรดกรอกชื่อ
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>นามสกุล</mat-label>
                            <input [formControl]="surname" [errorStateMatcher]="matcher" matInput>
                            <mat-error *ngIf="surname.hasError('required')">
                                โปรดกรอกนามสกุล
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>เพศ</mat-label>
                            <mat-select [formControl]="sex" [errorStateMatcher]="matcher">
                                <mat-option value="ชาย">ชาย</mat-option>
                                <mat-option value="หญิง">หญิง</mat-option>
                            </mat-select>
                            <mat-error *ngIf="sex.hasError('required')">
                                โปรดกรอกเพศ
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <input matInput [matDatepicker]="picker" placeholder="วันเกิด" [formControl]="birthday"
                                [errorStateMatcher]="matcher">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker [touchUi]="isSmallScreen" #picker></mat-datepicker>
                            <mat-error *ngIf="birthday.hasError('required')">
                                โปรดกรอกวันเกิด
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>อายุ</mat-label>
                            <input [formControl]="age" [errorStateMatcher]="matcher" type="number" matInput>
                            <mat-error *ngIf="age.hasError('required')">
                                โปรดกรอกอายุ
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>เลขบัตรประจำตัวประชาชน</mat-label>
                            <input [formControl]="idCard" [errorStateMatcher]="matcher" type="number" matInput>
                            <mat-error *ngIf="idCard.hasError('required')">
                                โปรดกรอกเลขบัตรประจำตัวประชาชน
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>HN (ถ้าทราบ)</mat-label>
                            <input [formControl]="hm" matInput>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>เบอร์ไทรศัพท์</mat-label>
                            <input [formControl]="telphone" [errorStateMatcher]="matcher" type="number" matInput>
                            <mat-error *ngIf="telphone.hasError('required')">
                                โปรดกรอกเบอร์ไทรศัพท์
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>Line ID</mat-label>
                            <input [formControl]="lineId" matInput>
                            <mat-error *ngIf="lineId.hasError('required')">
                                โปรดกรอก Line ID
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <mat-form-field class="example-full-width">
                        <mat-label>แพทย์</mat-label>
                        <input type="text" matInput [formControl]="doctorPick" [errorStateMatcher]="matcher"
                            [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions| async" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-autocomplete>

                    </mat-form-field>

                    <h4 style="background-color: #F7F7F7;padding:0.5rem 1rem">
                        กรุณาเลือกวันเวลา
                        เข้ามาปรึกษา</h4>
                    <br />
                    <ngb-datepicker #dp [formControl]="appointDate"></ngb-datepicker>
                    <mat-error *ngIf="(appointDate.hasError('required') && submitted)">
                        โปรดเลือกวันที่สะดวก
                    </mat-error>
                    <br /><br />
                    <h4>เวลานัด</h4>
                    <div class="appointTime">
                        <ngb-timepicker [formControl]="appointTime" [hourStep]="1" [minuteStep]="15"></ngb-timepicker>
                    </div>
                    <mat-error *ngIf="(appointTime.hasError('required') && submitted)">
                        โปรดเลือกช่วงเวลาที่สะดวก
                    </mat-error>
                    <div>
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Note</mat-label>
                            <textarea [formControl]="note" matInput></textarea>

                        </mat-form-field>
                    </div>

                </div>
            </div>

        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" class="btn btn-outline-warning btn-lg  appoint-btn" mat-dialog-close>ยกเลิก</button>&nbsp;
        <button type="submit" class="btn btn-warning btn-lg appoint-btn">จองคิวปรึกษา</button>
    </mat-dialog-actions>

</form>