<div class="center">
    <div class="container-fluid title-bar">
        <h1>จองคิวปรึกษา Online</h1>
        <h2>(Telemedicine)</h2>
        <p>การปรึกษา Online ขึ้นกับความพร้อม และศักยภาพของแต่ละโรงพยาบาล</p>
        <p>หากไม่ได้รับการติดต่อกลับเจ้าหน้าที่ ท่านสามารถติดต่อ รพ.ใกล้บ้านเพื่อรับการตรวจได้</p>
    </div>
    <div class="container-fluid body">
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" id='telebody'>
            <div class="card">
                <div class="card-body">
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>ชื่อ</mat-label>
                            <input [formControl]="name" [errorStateMatcher]="matcher" matInput>

                            <mat-error *ngIf="name.hasError('required')">
                                โปรดกรอกชื่อ
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>นามสกุล</mat-label>
                            <input [formControl]="surname" [errorStateMatcher]="matcher" matInput>
                            <mat-error *ngIf="surname.hasError('required')">
                                โปรดกรอกนามสกุล
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>เพศ</mat-label>
                            <mat-select [formControl]="sex" [errorStateMatcher]="matcher">
                                <mat-option value="ชาย">ชาย</mat-option>
                                <mat-option value="หญิง">หญิง</mat-option>
                            </mat-select>
                            <mat-error *ngIf="sex.hasError('required')">
                                โปรดกรอกเพศ
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <input matInput [matDatepicker]="picker" placeholder="วันเกิด" [formControl]="birthday"
                                [errorStateMatcher]="matcher">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker [touchUi]="isSmallScreen" #picker></mat-datepicker>
                            <mat-error *ngIf="birthday.hasError('required')">
                                โปรดกรอกวันเกิด
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>อายุ</mat-label>
                            <input [formControl]="age" [errorStateMatcher]="matcher" type="number" matInput>
                            <mat-error *ngIf="age.hasError('required')">
                                โปรดกรอกอายุ
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>เลขบัตรประจำตัวประชาชน</mat-label>
                            <input [formControl]="idCard" [errorStateMatcher]="matcher" type="number" matInput>
                            <mat-error *ngIf="idCard.hasError('required')">
                                โปรดกรอกเลขบัตรประจำตัวประชาชน
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>HN (ถ้าทราบ)</mat-label>
                            <input [formControl]="hm" matInput>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>เบอร์ไทรศัพท์</mat-label>
                            <input [formControl]="telphone" [errorStateMatcher]="matcher" type="number" matInput>
                            <mat-error *ngIf="telphone.hasError('required')">
                                โปรดกรอกเบอร์ไทรศัพท์
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="example-full-width">
                            <mat-label>Line ID</mat-label>
                            <input [formControl]="lineId" matInput>
                            <mat-error *ngIf="lineId.hasError('required')">
                                โปรดกรอก Line ID
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <h4>เลือกโรงพยาบาลในพื้นที่ของท่าน</h4>
                    <div class="hos">
                        <p>
                            <ng-select [items]="cities" [formControl]="selectedCity" (change)="onChange($event)"
                                placeholder="จังหวัด">
                            </ng-select>
                            <mat-error *ngIf="(selectedCity.hasError('required') && submitted)">
                                โปรดเลือกจังหวัด
                            </mat-error>
                        </p>
                        <p>

                            <ng-select [items]="areas" [formControl]="selectedArea" placeholder="อำเภอ">
                            </ng-select>
                            <mat-error *ngIf="(selectedArea.hasError('required') && submitted)">
                                โปรดเลือกอำเภอ
                            </mat-error>
                        </p>
                        <p>
                            <ng-select [items]="hosList" [formControl]="hos" placeholder="โรงพยาบาล">
                            </ng-select>
                            <mat-error *ngIf="(hos.hasError('required') && submitted)">
                                โปรดเลือกโรงพยาบาล
                            </mat-error>
                        </p>
                    </div>
                    <h4>วันที่สะดวกในการให้เจ้าหน้าที่ VDO call</h4>
                    <ngb-datepicker #dp [formControl]="appointDate"></ngb-datepicker>
                    <mat-error *ngIf="(appointDate.hasError('required') && submitted)">
                        โปรดเลือกวันที่สะดวก
                    </mat-error>
                    <br /><br />
                    <h4>ช่วงเวลาที่สะดวกในการให้เจ้าหน้าที่ VDO call</h4>
                    <mat-radio-group [formControl]="appointTime" name="appointTime" required>
                        <div *ngFor="let time of appointTimes;let i = index;">
                            <mat-radio-button name="appointTime" value="{{i}}">{{time}}</mat-radio-button>
                        </div>

                    </mat-radio-group>
                    <mat-error *ngIf="(appointTime.hasError('required') && submitted)">
                        โปรดเลือกช่วงเวลาที่สะดวก
                    </mat-error>

                    <div class="appoint">
                        <button type="button" [routerLink]="['/']"
                            class="btn btn-outline-warning btn-lg appoint-btn">ยกเลิก</button>&nbsp; &nbsp;
                        <button type="submit" class="btn btn-warning btn-lg appoint-btn">จองคิวปรึกษา</button>

                    </div>
                </div>
            </div>
        </form>
    </div>


    <br />
</div>