import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScheduleService } from 'src/app/clinic/service/schedule.service';
import { ClinicInfo, ClinicService } from 'src/app/clinic/service/clinic.service';

@Component({
  selector: 'app-result-dialog',
  templateUrl: './result-dialog.component.html',
  styleUrls: ['./result-dialog.component.scss']
})
export class ResultDialogComponent implements OnInit {
  clinicInfo: ClinicInfo;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private clinicService: ClinicService,
    private scheduleService: ScheduleService) { }

  ngOnInit(): void {
    console.log(this.data)
    this.clinicInfo = this.clinicService.getClinicInfo();
  }
  cancel() {
    this.scheduleService.cancelSchedule(this.data.id, this.clinicInfo.url).then(() => this.snackBar.open(`ยกเลิกนัด ${this.data.refId} แล้ว`, '', { duration: 3000, }));

  }
  /*
  markAsUnCall(element) {
    this.appointmentService.markAsUnCalled(element.id).then(() => {
      this.snackBar.open(`ยกเลิกการติดต่อ ${element.refId} แล้ว`, '', { duration: 3000, })
      this.data.appointState = 0;
    });
  }
*/
}
