import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { CityService } from 'src/app/service/city.service';
import { AnswerService } from 'src/app/service/answer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { ClinicService, ClinicInfo } from '../../service/clinic.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-start-clinic',
  templateUrl: './start-clinic.component.html',
  styleUrls: ['./start-clinic.component.scss']
})
export class StartClinicComponent implements OnInit {
  public clinicInfo: ClinicInfo;
  constructor(private clinicService: ClinicService, private answerService: AnswerService, private router: Router, private route: ActivatedRoute, ) { }

  ngOnInit() {
    this.clinicInfo = this.clinicService.getClinicInfo();
    this.answerService.clear();
  }

  goToQuestion() {
    this.answerService.area(this.clinicInfo.area);
    this.answerService.city(this.clinicInfo.city);
    this.router.navigate(['./question'], { relativeTo: this.route });
  }

}
