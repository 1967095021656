import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { CityService } from 'src/app/service/city.service';
import { AnswerService } from 'src/app/service/answer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { ClinicService, ClinicInfo } from '../../service/clinic.service';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../service/auth.service';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  private clinicInfoSub: Subscription;
  public clinicInfo;
  private userInfoSub: Subscription;
  constructor(private authService: AuthService, private clinicService: ClinicService) { }

  ngOnInit() {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userInfoSub = this.authService.getUserInfo(user.uid).subscribe((userInfo) => {
          if (userInfo) {
            this.clinicInfoSub = this.clinicService.requestClinicInfo(userInfo.url).subscribe((value) => {
              this.clinicInfo = value;
              this.clinicService.setClinicInfo(this.clinicInfo);
            });
          }
        })
      }
      console.dir(user);
    });

  }

  ngOnDestroy() {
    this.userInfoSub?.unsubscribe();
    this.clinicInfoSub?.unsubscribe();
  }

}
