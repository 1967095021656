import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormGroupDirective, NgForm } from '@angular/forms';
import { CityService } from 'src/app/service/city.service';
import { AnswerService } from 'src/app/service/answer.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { ClinicService, ClinicInfo } from '../../service/clinic.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from '../../service/auth.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignupResultComponent } from './signup-result/signup-result.component';

export function nameRealidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const pass = nameRe.test(control.value);
    return pass ? null : { 'nameRealidator': { value: control.value } };
  };
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
  public error: string;
  profileForm = new FormGroup({
    clinicName: new FormControl(null, [Validators.required,]),
    url: new FormControl(null, [Validators.required, nameRealidator(/^[a-zA-Z0-9]*$/)]),
    area: new FormControl(null, [Validators.required,]),
    city: new FormControl(null, [Validators.required,]),
    telphone: new FormControl(null, [Validators.required,]),
    LineId: new FormControl(null, [Validators.required,]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    website: new FormControl(),
    doctorName: new FormControl(null, [Validators.required,]),
    password: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(20)])
  });
  hide = true;
  get clinicName() { return this.profileForm.get('clinicName'); }
  get url() { return this.profileForm.get('url'); }
  get area() { return this.profileForm.get('area'); }
  get city() { return this.profileForm.get('city'); }
  get telphone() { return this.profileForm.get('telphone'); }
  get LineId() { return this.profileForm.get('LineId'); }
  get email() { return this.profileForm.get('email'); }
  get website() { return this.profileForm.get('website'); }
  get doctorName() { return this.profileForm.get('doctorName'); }
  get password() { return this.profileForm.get('password'); }

  cities;
  areas = [];

  filteredOptions: Observable<string[]>;
  filteredAreaOptions: Observable<string[]>;

  constructor(private authService: AuthService,
    private clinicService: ClinicService,
    private router: Router,
    private cityService: CityService) { }

  ngOnInit(): void {
    this.cities = this.cityService.getCities();

    this.filteredOptions = this.city.valueChanges
      .pipe(
        startWith(''),
        map(value => value ? this._cityfilter(value) : this.cities.slice())
      );

    this.city.valueChanges.subscribe((value) => {
      this.onChange(value)
    })
  }

  private _cityfilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    console.log(filterValue)
    return this.cities.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.areas.filter(option => option.toLowerCase().includes(filterValue));
  }

  onChange($event) {
    console.dir($event);
    this.area.setValue(null);
    this.areas = this.cityService.getAreas($event);
    if (this.areas == undefined) {
      this.areas = [];
    }
    console.log(this.areas)

    this.filteredAreaOptions = this.area.valueChanges
      .pipe(
        startWith(''),
        map(value => value ? this._filter(value) : this.areas.slice())
      );
  }
  testResult() {
    this.clinicService.requestClinicInfo("test2").subscribe((value) => {
      this.clinicService.setClinicInfo(value);
      this.showResult()
    })

  }
  showResult() {

    //this.modalService.open(SignupResultComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', scrollable: true });
    this.router.navigate(['signup-result']);
  }
  private isSubmit = false;
  async onSubmit() {
    //this.testResult();

    if (this.profileForm.invalid == false && this.isSubmit == false) {
      this.isSubmit = true;
      let createdUser: firebase.auth.UserCredential;

      try {
        const isExist = await this.clinicService.isClinicUrlExist(this.url.value);
        if (!isExist) {

          const clinicInfo: ClinicInfo = new ClinicInfo();
          clinicInfo.name = this.clinicName.value;
          clinicInfo.url = this.url.value;
          clinicInfo.area = this.area.value;
          clinicInfo.city = this.city.value;
          clinicInfo.telphone = this.telphone.value;
          clinicInfo.LineId = this.LineId.value;
          clinicInfo.email = this.email.value;
          clinicInfo.website = this.website.value;
          clinicInfo.doctorName = this.doctorName.value;

          createdUser = await this.authService.Register(this.email.value, this.password.value);

          await this.clinicService.createClinicInfo(clinicInfo);
          this.clinicService.setClinicInfo(clinicInfo);


          await this.authService.createUserInfo(createdUser.user.uid, this.url.value);

          this.showResult();


        } else {
          this.error = "มีคนใช้ Url นี้แล้ว โปรดเลือก Url ใหม่";
          this.isSubmit = false;
        }
      } catch (error) {
        console.error(error);
        if (createdUser) {
          console.log("createdUser")
          console.log(createdUser)
          this.authService.deleteUser(createdUser.user.uid);
          this.authService.deleteUserInfo(createdUser.user.uid);
        }
        this.clinicService.deleteClinicInfo(this.url.value);
        this.error = "Internal Error";
        this.isSubmit = false;
      }

    }
  }
}
