import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription, Observable } from 'rxjs';
import { ClinicInfo, ClinicService } from '../../service/clinic.service';
import { ScheduleService, Schedule } from '../../service/schedule.service';
import { AnswerService } from 'src/app/service/answer.service';
import { startWith, map } from 'rxjs/operators';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {



  profileForm = new FormGroup({
    name: new FormControl(null, [Validators.required,]),
    surname: new FormControl(),
    sex: new FormControl(),
    birthday: new FormControl(),
    age: new FormControl(),
    hm: new FormControl(),
    idCard: new FormControl(),
    telphone: new FormControl(null, [Validators.required,]),
    lineId: new FormControl(),
    note: new FormControl(),
    doctorPick: new FormControl(),
    appointDate: new FormControl(),
    appointTime: new FormControl({
      "hour": 8,
      "minute": 30
    })
  });

  matcher = new MyErrorStateMatcher();

  get name() { return this.profileForm.get('name'); }
  get surname() { return this.profileForm.get('surname'); }
  get sex() { return this.profileForm.get('sex'); }
  get birthday() { return this.profileForm.get('birthday'); }
  get age() { return this.profileForm.get('age'); }
  get hm() { return this.profileForm.get('hm'); }
  get idCard() { return this.profileForm.get('idCard'); }
  get telphone() { return this.profileForm.get('telphone'); }
  get lineId() { return this.profileForm.get('lineId'); }
  get appointDate() { return this.profileForm.get('appointDate'); }
  get appointTime() { return this.profileForm.get('appointTime'); }
  get note() { return this.profileForm.get('note'); }
  get doctorPick() { return this.profileForm.get('doctorPick'); }

  submitted = false;

  isSmallScreen = false;
  layoutSub: Subscription;

  public clinicInfo: ClinicInfo;
  filteredOptions: Observable<string[]>;

  constructor(private route: ActivatedRoute,
    private clinicService: ClinicService,
    private scheduleService: ScheduleService,
    private answerService: AnswerService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private calendar: NgbCalendar) { }

  ngOnInit(): void {


    //public clinicInfo: Observable<ClinicInfo>;
    this.clinicInfo = this.clinicService.getClinicInfo();
    //   if (this.answerService.saved == false)
    //     this.router.navigate(['/']);

    this.appointDate.setValue(this.calendar.getToday());

    this.filteredOptions = this.doctorPick.valueChanges
      .pipe(
        startWith(''),
        map(value => value ? this._doctorListfilter(value) : this.clinicInfo.doctorList.slice())
      );


    const layoutChanges = this.breakpointObserver.observe([
      '(max-width: 768px)'
    ]);
    this.layoutSub = layoutChanges.subscribe(result => {
      this.isSmallScreen = result.matches;
    });

  }

  private _doctorListfilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    console.log(filterValue)
    return this.clinicInfo.doctorList.filter(option => option.toLowerCase().includes(filterValue));
  }

  ngOnDestroy() {
    if (this.layoutSub)
      this.layoutSub.unsubscribe();
  }


  onSubmit() {
    if (this.profileForm.valid) {
      this.submitted = true;
      const appointDate = this.appointDate.value;
      const date = new Date(appointDate.year, appointDate.month - 1, appointDate.day);

      let data: Schedule = new Schedule();

      data.name = this.name.value;
      data.surname = this.surname.value;
      data.sex = this.sex.value;
      data.birthday = this.birthday.value;
      data.age = this.age.value;
      data.hm = this.hm.value;
      data.idCard = this.idCard.value;
      data.telphone = this.telphone.value;
      data.lineId = this.lineId.value;
      data.createdDate = new Date().getTime();
      data.appointDate = this.appointDate.value;
      data.appointDateMilli = date.getTime();
      data.appointTime = this.appointTime.value;
      data.appointTimeString = data.appointTime.hour + ":" + data.appointTime.minute;
      data.appointState = 0;
      data.note = this.note.value;
      data.doctorPick = this.doctorPick.value;

      data.screenData = this.answerService.data;
      data = this.scheduleService.getIdIfNew(data);
      this.scheduleService.set(data, this.clinicInfo.url).then(() => {
        this.router.navigate(['./../schedule-result'], { relativeTo: this.route });
      });
    }

  }

}
