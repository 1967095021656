<mat-dialog-content class="mat-typography">
    <div class="row center">
        <h4 *ngIf="!showLogin" class="modal-title center" id="modal-basic-title">QR Code</h4>
        <h4 *ngIf="showLogin" class="modal-title center" id="modal-basic-title">ลงทะเบียนสำเร็จแล้ว</h4>
    </div>
    <div class="card-body">
        <div class="row" *ngIf="showLogin">
            <div class="col-12 text-center"><a href="/login">Login เข้าระบบ</a></div>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-12 col-lg-3">
                <div class="center">
                    <div class="mat-elevation-z8">
                        <svg *ngIf="this.qrCodeUri" id="screenId" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 424 406">
                            <defs>
                                <filter id="Rectangle_132" x="0" y="0" width="424" height="406"
                                    filterUnits="userSpaceOnUse">
                                    <feOffset dy="3" input="SourceAlpha" />
                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                    <feFlood flood-opacity="0.161" />
                                    <feComposite operator="in" in2="blur" />
                                    <feComposite in="SourceGraphic" />
                                </filter>
                            </defs>
                            <g id="Group_144" data-name="Group 144" transform="translate(-8750 -12428)">

                                <image id="hmstopcovid" width="280" height="280" transform="translate(8825 12538)"
                                    [attr.xlink:href]="this.qrCodeUri" />
                                <text id="www.ติดไม่ติด.com" transform="translate(8962 12815)" font-size="15"
                                    font-family="Kanit-Light, Kanit" font-weight="300">
                                    <tspan x="-58.575" y="0">www.</tspan>
                                    <tspan y="0">ติดไม่ติด.</tspan>
                                    <tspan y="0">com</tspan>
                                </text>
                                <g id="Group_143" data-name="Group 143" transform="translate(8775 12455)">
                                    <rect id="Rectangle_140" data-name="Rectangle 140" width="375" height="32"
                                        fill="#ff9e00" />
                                </g>
                                <text id="กรุณากรอกแบบคัดกรองก่อนทำการรักษา" transform="translate(8930 12480)"
                                    fill="#001f74" font-size="22" fill="#fff" font-family="Kanit-Regular, Kanit">
                                    <tspan x="-150.363" y="0">กรุณากรอกแบบคัดกรองก่อนทำการรักษา</tspan>
                                </text>

                                <text y="-22" transform="translate(8962 12542)" fill="#343434"
                                    font-family="Kanit-Medium, Kanit" font-weight="500">
                                    <tspan x="0" text-anchor="middle" [attr.font-size]="nameSize">{{clinicInfo.name}}
                                    </tspan>
                                    <tspan x="0" text-anchor="middle" font-size="15" dy="25">{{clinicInfo.city}}</tspan>
                                </text>

                            </g>
                        </svg>
                    </div>


                    <div class="center">
                        <h1>คัดกรอง</h1>
                    </div>
                    <div class="center">
                        <button type="button" class="btn btn-outline-dark"
                            (click)="downloadMyFile('screenId','screen.png')">Download
                            QR</button>
                    </div>
                    <div class="center">
                        <button type="button" class="btn btn-outline-dark" [cdkCopyToClipboard]="screenUrl"
                            ngbPopover="Copied:{{screenUrl}}">Copy
                            link</button>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-lg-3">
                <div class="center">
                    <div class="mat-elevation-z8">
                        <svg *ngIf="this.qrCodeScheduleUri" id="scheduleId" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 424 406">
                            <defs>
                                <filter id="Rectangle_132" x="0" y="0" width="424" height="406"
                                    filterUnits="userSpaceOnUse">
                                    <feOffset dy="3" input="SourceAlpha" />
                                    <feGaussianBlur stdDeviation="3" result="blur" />
                                    <feFlood flood-opacity="0.161" />
                                    <feComposite operator="in" in2="blur" />
                                    <feComposite in="SourceGraphic" />
                                </filter>
                            </defs>
                            <g id="Group_144" data-name="Group 144" transform="translate(-8750 -12428)">

                                <image id="hmstopcovid" width="280" height="280" transform="translate(8825 12538)"
                                    [attr.xlink:href]="this.qrCodeScheduleUri" />
                                <text id="www.ติดไม่ติด.com" transform="translate(8962 12815)" font-size="15"
                                    font-family="Kanit-Light, Kanit" font-weight="300">
                                    <tspan x="-58.575" y="0">www.</tspan>
                                    <tspan y="0">ติดไม่ติด.</tspan>
                                    <tspan y="0">com</tspan>
                                </text>
                                <g id="Group_143" data-name="Group 143" transform="translate(8775 12455)">
                                    <rect id="Rectangle_140" data-name="Rectangle 140" width="375" height="32"
                                        fill="#6F3DF8" />
                                </g>
                                <text id="จองคิว Online" transform="translate(8900 12480)" fill="#001f74" font-size="22"
                                    fill="#fff" font-family="Kanit-Regular, Kanit">
                                    <tspan x="0" y="0">จองคิว Online</tspan>
                                </text>


                                <text y="-22" transform="translate(8962 12542)" fill="#343434"
                                    font-family="Kanit-Medium, Kanit" font-weight="500">
                                    <tspan x="0" text-anchor="middle" [attr.font-size]="nameSize">{{clinicInfo.name}}
                                    </tspan>
                                    <tspan x="0" text-anchor="middle" font-size="15" dy="25">{{clinicInfo.city}}</tspan>
                                </text>
                            </g>
                        </svg>
                    </div>
                    <div class="center">
                        <h1>จองคิว</h1>
                    </div>
                    <div class="center">
                        <button type="button" class="btn btn-outline-dark"
                            (click)="downloadMyFile('scheduleId','schedule.png')">Download
                            QR</button>
                    </div>
                    <div class="center">
                        <button type="button" class="btn btn-outline-dark" [cdkCopyToClipboard]="scheduleUrl"
                            ngbPopover="Copied:{{scheduleUrl}}">Copy
                            link</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>