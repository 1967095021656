import { Component, OnInit } from '@angular/core';
import { AnswerService } from 'src/app/service/answer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ClinicService } from 'src/app/clinic/service/clinic.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
  resultId = 0;
  public clinicInfo;

  constructor(private router: Router,
    private answerService: AnswerService,
    private route: ActivatedRoute,
    private clinicService: ClinicService) { }

  ngOnInit(): void {
    this.clinicInfo = this.clinicService.getClinicInfo();

    if (this.answerService.saved == false)
      this.router.navigate(['./..'], { relativeTo: this.route });
    this.resultId = this.answerService.getResultId();

  }



}
