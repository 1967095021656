<div class="center">
    <div class="container-fluid title-bar">
        <h1>จองคิว Online</h1>
        <h2>ก่อนเข้ามารักษาทางทันตกรรม</h2>
        <h1>{{this.clinicInfo.name}} </h1>
        <h2>{{this.clinicInfo.city}}</h2>
    </div>
    <div class="container-fluid body">
        <div class="result">
            กรุณารอการติดต่อกลับ
            <br />
            จากทางคลินิก
            <br />
            เพื่อยืนยัน วันและเวลานัด ของท่าน
        </div>
        <br />
        <div class="datetime">
            {{dateString}}
        </div>
        <div class="datetime">
            เวลา {{this.scheduleResult?.appointTimeString}}
        </div>
        <div class="datetime">
            {{this.clinicInfo.name}}
        </div>
        <div class="datetime">
            Reference No. {{this.scheduleResult?.refId}}
        </div>
        <br />
        <button type="button" [routerLink]="['./../']" class="btn btn-outline-warning btn-lg">กลับหน้าหลัก</button>
    </div>



</div>