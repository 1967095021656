import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ClinicInfo, ClinicService } from './clinic.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription, Observable } from 'rxjs';

export class UserInfo {
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  clinicInfo: ClinicInfo;
  user: Observable<firebase.User>;
  //userInfoSub: Subscription;
  //clinicInfoSub: Subscription;
  constructor(private auth: AngularFireAuth, private afs: AngularFirestore, private clinicService: ClinicService) {
    this.user = this.auth.user;
    /*this.auth.user.subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.userInfoSub = this.getUserInfo(user.uid).subscribe((userInfo) => {
          if (userInfo) {
            this.clinicInfoSub = this.clinicService.requestClinicInfo(userInfo.url).subscribe((value) => this.clinicInfo = value);
          }
        })
      } else {
        this.userInfoSub?.unsubscribe();
        this.clinicInfoSub?.unsubscribe();
      }
      console.dir(user);
    })*/
  }

  SignIn(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  Register(email: string, password: string) {
    return this.auth.createUserWithEmailAndPassword(email, password)
  }

  deleteUser(uuid: string) {
    //TODO: delte users
  }

  createUserInfo(uid: string, url: string) {
    return this.afs.doc<UserInfo>('clinic-user/' + uid).set({ url: url });
  }

  deleteUserInfo(uid: string) {
    return this.afs.doc<UserInfo>('clinic-user/' + uid).delete();
  }



  getUserInfo(uid: string) {
    return this.afs.doc<UserInfo>('clinic-user/' + uid).valueChanges();
  }
  // save clinicinfo
  //return this.auth.signInWithEmailAndPassword(email, password);


}

