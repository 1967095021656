<div class="">
    <div class="container-fluid title-bar center">
        <h1>{{clinicInfo.name}} </h1>
        <h2>{{clinicInfo.city}}</h2>
    </div>
    <div class="container-fluid">
        <form class="form-inline">
            <div class="form-group hidden">
                <div class="input-group">
                    <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                        [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                        [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!">
                    <ng-template #t let-date let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                            (mouseleave)="hoveredDate = null">
                            {{ date.day }}
                        </span>
                    </ng-template>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <input #dpFromDate class="form-control" placeholder="yyyy-mm-dd" name="dpFromDate"
                        [value]="formatter.format(fromDate)"
                        (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()"
                            type="button"></button>
                    </div>
                </div>
            </div>
            <div class="form-group ml-2">
                <div class="input-group">
                    <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
                        [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()"
                            type="button"></button>
                    </div>
                </div>
            </div>
            <div class="form-group ml-5">
                <button type="button" (click)="openEditDialog()" class="btn btn-primary">เพิ่มนัด</button>
            </div>
            <div class="form-group ml-5" style="padding-top: 20px;">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>แพทย์</mat-label>
                    <mat-select [(ngModel)]="searchDoctor" name="searchDoctor" (selectionChange)="applyDoctorFilter()">
                        <mat-option>Clear</mat-option>
                        <mat-option *ngFor="let element of doctorPickList" [value]="element?element:'*ไม่ระบุแพทย์*'">
                            {{element?element:"*ไม่ระบุแพทย์*"}}</mat-option>
                    </mat-select>

                </mat-form-field>
            </div>

        </form>

        <div class="mat-elevation-z1">
            <mat-form-field class="example-full-width">
                <input matInput (keyup)="applyFilter($event)" placeholder="Search">

            </mat-form-field>
            <table mat-table matTableExporter #exporter="matTableExporter" matSort [dataSource]="dataSource">

                <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ชื่อ </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} {{element.surname}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="hn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> HN </th>
                    <td mat-cell *matCellDef="let element"> {{element.hn}} </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="refId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ref Id </th>
                    <td mat-cell *matCellDef="let element"> {{element.refId}} </td>
                </ng-container>

                <ng-container matColumnDef="doctorPick">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>แพทย์</th>
                    <td mat-cell *matCellDef="let element"> {{element.doctorPick}} </td>
                </ng-container>

                <ng-container matColumnDef="screenResult">
                    <th mat-header-cell *matHeaderCellDef> ผลประเมิน </th>
                    <td mat-cell *matCellDef="let element"> {{element.screenData?.resultId |StateName}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> วันที่ </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.appointDate.day}}/{{element.appointDate.month}}/{{element.appointDate.year}}
                        {{element.appointTimeString}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="telphone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Telphone </th>
                    <td mat-cell *matCellDef="let element"> {{element.telphone}} </td>
                </ng-container>
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef> สถานะ </th>
                    <td mat-cell *matCellDef="let element"><span [class.cancel]="element.appointState === 1">
                            {{element.appointState |appointstate}} </span></td>
                </ng-container>
                <ng-container matColumnDef="button">
                    <th mat-header-cell *matHeaderCellDef>
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="exporter.exportTable('xls')">
                                <span>Export to XLS</span>
                            </button>
                            <button mat-menu-item (click)="exporter.exportTable('xlsx')">
                                <span>Export to XLSX</span>
                            </button>
                            <button mat-menu-item (click)="exporter.exportTable('csv')">
                                <span>Export to CVS</span>
                            </button>
                            <button mat-menu-item (click)="exporter.exportTable('txt')">
                                <span>Export to TXT</span>
                            </button>
                        </mat-menu>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button type="button" class="btn btn-outline-dark btn-sm"
                            (click)="openDialog(element)">View</button>&nbsp;
                        <button type="button" (click)="openEditDialog(element)"
                            class="btn btn-primary btn-sm">Edit</button>&nbsp;
                        <button class="btn btn-sm" (click)="cancelSchedule(element)"
                            [ngClass]="(element.appointState==0)?'btn-danger':'btn-light'"
                            [disabled]="element.appointState != 0"> ยกเลิกนัด</button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5,10, 20]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="row justify-content-sm-center">
            <div class="col-lg-3 col-md-6 col-12" style="padding-top: 1rem;">
                <div class="card" id="summery">
                    <h6 class="card-title">จำนวนผู้ทำแบบคัดกรอง ในพื้นที่ของท่าน</h6>
                    <div class="card-body">
                        {{(summeryDoc| async)?.numberOfDocs}}
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12" style="padding-top: 1rem;">
                <div class="card" id="summery-type">
                    <h6 class="card-title">เข้าเกณฑ์</h6>
                    <div class="card-body">
                        {{(summeryDoc| async)?.standardizeDocs}}
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12" style="padding-top: 1rem;">
                <div class="card" id="summery-type">
                    <h6 class="card-title">เฝ้าระวัง</h6>
                    <div class="card-body">
                        {{(summeryDoc| async)?.watchOutDocs}}
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12" style="padding-top: 1rem;">
                <div class="card" id="summery-type">
                    <h6 class="card-title">ไม่พบประวัติ</h6>
                    <div class="card-body">
                        {{(summeryDoc| async)?.cleanDocs}}
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>