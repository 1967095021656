import { Component, OnInit } from '@angular/core';
import { CityService } from 'src/app/service/city.service';
import { FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AnswerService } from 'src/app/service/answer.service';
import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-telemedic',
  templateUrl: './telemedic.component.html',
  styleUrls: ['./telemedic.component.scss']
})
export class TelemedicComponent implements OnInit {
  profileForm = new FormGroup({
    name: new FormControl(null, [Validators.required,]),
    surname: new FormControl(null, [Validators.required,]),
    sex: new FormControl(null, [Validators.required,]),
    birthday: new FormControl(null, [Validators.required,]),
    age: new FormControl(null, [Validators.required]),
    hm: new FormControl(),
    idCard: new FormControl(null, [Validators.required,]),
    telphone: new FormControl(null, [Validators.required,]),
    lineId: new FormControl(null, [Validators.required]),
    appointDate: new FormControl(null, [Validators.required,]),
    appointTime: new FormControl(null, [Validators.required,]),
    selectedCity: new FormControl(null, [Validators.required,]),
    selectedArea: new FormControl(null, [Validators.required,]),
    hos: new FormControl(null, [Validators.required,])

  });

  matcher = new MyErrorStateMatcher();

  get name() { return this.profileForm.get('name'); }
  get surname() { return this.profileForm.get('surname'); }
  get sex() { return this.profileForm.get('sex'); }
  get birthday() { return this.profileForm.get('birthday'); }
  get age() { return this.profileForm.get('age'); }
  get hm() { return this.profileForm.get('hm'); }
  get idCard() { return this.profileForm.get('idCard'); }
  get telphone() { return this.profileForm.get('telphone'); }
  get lineId() { return this.profileForm.get('lineId'); }
  get appointDate() { return this.profileForm.get('appointDate'); }
  get appointTime() { return this.profileForm.get('appointTime'); }
  get selectedCity() { return this.profileForm.get('selectedCity'); }
  get selectedArea() { return this.profileForm.get('selectedArea'); }
  get hos() { return this.profileForm.get('hos'); }

  cities;
  areas;
  hosList;
  submitted = false;

  appointTimes = ['09:00 - 11:00', '11:00 - 13:00', '13:00 - 15:00', '15:00 - 17:00']
  isSmallScreen = false;
  layoutSub: Subscription;
  constructor(private cityService: CityService,
    private afs: AngularFirestore,
    private answerService: AnswerService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private calendar: NgbCalendar) { }

  ngOnInit(): void {
    //   if (this.answerService.saved == false)
    //     this.router.navigate(['/']);

    this.cities = this.cityService.getCities();
    this.appointDate.setValue(this.calendar.getToday());
    this.selectedCity.setValue(this.answerService.data.city);
    this.areas = this.cityService.getAreas(this.answerService.data.city);
    this.selectedArea.setValue(this.answerService.data.area);
    this.hosList = this.cityService.getHos(this.answerService.data.city);

    const layoutChanges = this.breakpointObserver.observe([
      '(max-width: 768px)'
    ]);
    this.layoutSub = layoutChanges.subscribe(result => {
      this.isSmallScreen = result.matches;
    });

  }

  ngOnDestroy() {
    if (this.layoutSub)
      this.layoutSub.unsubscribe();
  }
  onChange($event) {
    console.log('change' + $event);
    this.selectedArea.setValue(null);
    this.hos.setValue(null);
    this.areas = this.cityService.getAreas($event);
    this.hosList = this.cityService.getHos($event);
  }

  onSubmit() {
    console.log('submit');
    this.submitted = true;
    if (this.profileForm.valid) {
      console.log('valid');
      const appointDate = this.appointDate.value;
      const date = new Date(appointDate.year, appointDate.month - 1, appointDate.day);

      this.answerService.appointData = {
        name: this.name.value,
        surname: this.surname.value,
        sex: this.sex.value,
        birthday: this.birthday.value,
        age: this.age.value,
        hm: this.hm.value,
        idCard: this.idCard.value,
        telphone: this.telphone.value,
        lineId: this.lineId.value,
        appointDate: this.appointDate.value,
        appointDateMilli: date.getTime(),
        appointTime: this.appointTime.value,
        appointTimeString: this.appointTimes[this.appointTime.value],
        appointCity: this.selectedCity.value,
        appointArea: this.selectedArea.value,
        appointCityArea: this.selectedCity.value + "-" + this.selectedArea.value,
        hospital: this.hos.value,
        appointState: 0,
        isAppoint: true,
        refId: new Date().getTime() + Math.floor((Math.random() * 100) + 1)
      };
      const param = JSON.parse(JSON.stringify(this.answerService.appointData));
      console.log(param);
      this.afs.collection('data').doc(this.answerService.data.id).update(param).then((value) => {
        this.router.navigate(['telemedic-result']);
      });
    }

  }

}
