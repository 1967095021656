import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { CityService } from 'src/app/service/city.service';
import { AnswerService } from 'src/app/service/answer.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subscription } from 'rxjs';
import { ClinicInfo, ClinicService } from 'src/app/clinic/service/clinic.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ScheduleService, Schedule } from 'src/app/clinic/service/schedule.service';
import { NgbCalendar, NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ResultDialogComponent } from '../result-dialog/result-dialog.component';
import { EditScheduleComponent } from '../edit-schedule/edit-schedule.component';
import { SummeryService, Summery } from 'src/app/clinic/service/summery.service';
import { Summary } from '@angular/compiler';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

class FilterValue {
  text: string;
  doctor: string;
}

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit {
  public clinicInfo: ClinicInfo;
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  summeryDoc: Observable<Summery>;

  displayedColumns: string[] = ['date', 'name', 'hn', 'telphone', 'doctorPick', 'state', 'screenResult', 'button'];
  dataSource = new MatTableDataSource<Schedule>();
  scheduleServicesSub: Subscription;

  private clinicInfoSub: Subscription;
  result: string = '';
  public doctorPickList: string[];
  public searchDoctor = "";

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private filterText: string = '';

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    const i = new FilterValue();
    i.text = filterValue.trim().toLowerCase();
    this.filterText = i.text;
    i.doctor = this.searchDoctor;
    this.dataSource.filter = JSON.stringify(i);
  }

  applyDoctorFilter() {
    const i = new FilterValue();
    i.text = this.filterText;
    i.doctor = this.searchDoctor;
    this.dataSource.filter = JSON.stringify(i);
  }

  constructor(private clinicService: ClinicService,
    private scheduleService: ScheduleService,
    private summeryService: SummeryService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter) {

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);

    this.dataSource.sortingDataAccessor = (item, property): string | number => {
      switch (property) {
        case 'date': {
          const date = new Date(item.appointDateMilli);
          date.setHours(item.appointTime.hour);
          date.setMinutes(item.appointTime.minute);
          return date.getTime();
        };
        default: return item[property];
      }
    };
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.reloaddata();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  openDialog(element) {
    const dialogRef = this.dialog.open(ResultDialogComponent, {
      data: element, width: "1200px",
    });
  }

  openEditDialog(element?) {
    const dialogRef = this.dialog.open(EditScheduleComponent, {
      data: element, width: "1200px",
    });
  }

  ngOnInit() {
    this.clinicInfo = this.clinicService.getClinicInfo();
    this.clinicInfoSub = this.clinicService.requestClinicInfo(this.clinicService.getClinicInfo().url).subscribe((value) => {
      this.clinicInfo = value;
      this.reloaddata();
    })

    this.summeryDoc = this.summeryService.getSummery(this.clinicInfo.url)

    this.dataSource.filterPredicate = function (data, filter: string): boolean {

      const result: FilterValue = JSON.parse(filter)
      let compare = data.doctorPick;
      if (!compare) {
        compare = "*ไม่ระบุแพทย์*"
      }
      if (result.doctor && result.doctor !== compare) {
        return false;
      }
      return data.name.toLowerCase().includes(result.text) ||
        data.surname?.toLowerCase().includes(result.text) ||
        data.hm === result.text ||
        data.telphone === result.text

    };

  }

  ngOnDestroy() {
    this.clinicInfoSub?.unsubscribe();
  }

  cancelSchedule(element) {
    const message = `ต้องการยกเลิกนัดของ ${element.name}?`;
    const dialogData = new ConfirmDialogModel("ยกเลิกนัด", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.scheduleService.cancelSchedule(element.id, this.clinicInfo.url).then(() => this.snackBar.open(`ยกเลิกนัด ${element.refId} แล้ว`, '', { duration: 3000, }));
      }
      console.log(this.result)
    });
    // this.scheduleService.cancelSchedule(element.id, this.clinicInfo.url).then(() => this.snackBar.open(`ยกเลิกนัด ${element.refId} แล้ว`, '', { duration: 3000, }));
  }

  reloaddata() {
    if (this.scheduleServicesSub) {
      this.scheduleServicesSub.unsubscribe();
    }
    const from = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)
    let to = from;
    if (this.toDate)
      to = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day)
    this.scheduleServicesSub = this.scheduleService.getSchedules(this.clinicInfo.url, from.getTime(), to.getTime()).subscribe((value) => {
      this.dataSource.data = value;
      this.doctorPickList = [...new Set(value.map(item => item.doctorPick == undefined ? '' : item.doctorPick))];

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

  }

}
