import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

export class Data {
  public id: string;
  public cityArea;
  public cityAreaTownShip;
  public city;
  public area;
  public townShip;

  public dentalUrl: string;

  public q1: boolean;
  public q1_date: string;
  public q1_detail: string;

  public q2: boolean;
  public q2_city: string;
  public q2_place: string;
  public q2_date: string;
  public q2_Time: string;
  public q2_Other: string;

  public q3: boolean;
  public q3_relation: string;
  public q3_detail: string;

  public q4: boolean;
  public q4_career: string;
  public q4_other: string;

  public q5: boolean;
  public q5_medCareer: string;
  public q5_other: string;

  public isfever = false;
  public temp;
  public isCough = false;
  public isSnot = false;
  public isSoreThroat = false;
  public isGasp = false;

  public resultId: number = 0;
  public isClean;
  public datetime;
  public datetimeMilisecond;
}

@Injectable({
  providedIn: 'root'
})
export class AnswerService {
  public data: Data = new Data();
  public saved: boolean = false;
  public first: boolean = false;
  public appointData;

  private isClean = false;

  clear() {
    this.data = new Data();
    this.saved = false;
    this.first = false;
    this.appointData = null;
  }

  townShip(value: string) {
    this.data.townShip = value;
  }
  city(value: string) {
    this.data.city = value;
  }

  area(value: string) {
    this.data.area = value;
  }

  q1(value: boolean) {
    this.data.q1 = value;
  }

  q1_date(value: string) {
    this.data.q1_date = value;
  }

  q1_detail(value: string) {
    this.data.q1_detail = value;
  }

  q2(value: boolean) {
    this.data.q2 = value;
  }

  q2_city(value: string) {
    this.data.q2_city = value;
  }

  q2_place(value: string) {
    this.data.q2_place = value;
  }

  q2_date(value: string) {
    this.data.q2_date = value;
  }

  q2_Time(value: string) {
    this.data.q2_Time = value;
  }

  q2_Other(value: string) {
    this.data.q2_Other = value;
  }

  q3(value: boolean) {
    this.data.q3 = value;
  }

  q3_relation(value: string) {
    this.data.q3_relation = value;
  }

  q3_detail(value: string) {
    this.data.q3_detail = value;
  }

  q4(value: boolean) {
    this.data.q4 = value;
  }

  q4_career(value: string) {
    this.data.q4_career = value;
  }

  q4_other(value: string) {
    this.data.q4_other = value;
  }

  q5(value: boolean) {
    this.data.q5 = value;
  }

  q5_medCareer(value: string) {
    this.data.q5_medCareer = value;
  }

  q5_other(value: string) {
    this.data.q5_other = value;
  }

  isfever(value: boolean) {
    this.data.isfever = value;
  }
  temp(value: string) {
    this.data.temp = value;
  }
  isSnot(value: boolean) {
    this.data.isSnot = value;
  }
  isCough(value: boolean) {
    this.data.isCough = value;
  }

  isSoreThroat(value: boolean) {
    this.data.isSoreThroat = value;
  }
  isGasp(value: boolean) {
    this.data.isGasp = value;
  }

  getResultId(): number {
    return this.data.resultId;
  }

  cityArea(): string {
    return this.data.cityArea;
  }

  constructor(private afs: AngularFirestore) { }


  calcurate(): number {
    this.data.resultId = this.screening();
    return this.data.resultId;
  }

  screening(): number {

    let numberSymptom = 0;
    if (this.data.isfever)
      numberSymptom++;
    if (this.data.isCough)
      numberSymptom++;
    if (this.data.isSnot)
      numberSymptom++;
    if (this.data.isSoreThroat)
      numberSymptom++;
    const numberSymptomWithOutFive = numberSymptom;
    if (this.data.isGasp)
      numberSymptom++;

    //1
    if ((this.data.q1 || this.data.q2 || this.data.q3 || this.data.q4 || this.data.q5)
      && numberSymptom == 0)
      return 1;

    //2
    if (!(this.data.q1 || this.data.q2 || this.data.q3 || this.data.q4 || this.data.q5)
      && (numberSymptom > 0)) {
      this.isClean = true;
      return 2;
    }
    //3
    if ((this.data.q1 || this.data.q2)
      && (numberSymptom >= 2))
      return 3;
    //4
    if ((this.data.q1 || this.data.q2)
      && (this.data.isGasp && numberSymptomWithOutFive == 0))
      return 3;

    //5
    if ((this.data.q1 || this.data.q2)
      && (numberSymptomWithOutFive == 1)
    )
      return 1;

    //6
    if ((this.data.q3)
      && (numberSymptom >= 2))
      return 3;
    //7
    if ((this.data.q3)
      && (this.isGasp && numberSymptomWithOutFive == 0))
      return 3;

    //8
    if ((this.data.q3)
      && (numberSymptomWithOutFive == 1))
      return 4;

    //9
    if ((this.data.q1 || this.data.q2) && this.data.q3
      && (numberSymptom >= 2))
      return 3;

    //10
    if ((this.data.q1 || this.data.q2) && this.data.q3
      && (this.data.isGasp && numberSymptomWithOutFive == 0))
      return 3;

    //11
    if ((this.data.q1 || this.data.q2) && this.data.q3
      && (numberSymptomWithOutFive == 1))
      return 4;

    //12 //15
    if ((this.data.q4 || this.data.q5) && !(this.data.q1 || this.data.q2 || this.data.q3)
      && (numberSymptom >= 2))
      return 5;

    //13 //16
    if ((this.data.q4 || this.data.q5)
      && (this.data.isGasp))
      return 5;

    //14 //17
    if ((this.data.q4 || this.data.q5)
      && (numberSymptomWithOutFive == 1))
      return 6;

    return 2;

  }

  save() {
    this.data.cityArea = this.data.city + "-" + this.data.area;
    if (this.data.townShip)
      this.data.cityAreaTownShip = this.data.city + "-" + this.data.area + "-" + this.data.townShip;

    this.data.datetime = new Date();
    this.data.datetimeMilisecond = this.data.datetime.getTime();
    this.data.isClean = this.isClean;
    //let newRef = .doc();
    //this.data.id = newRef.ref.id;
    const param = JSON.parse(JSON.stringify(this.data));
    console.log(param);

    this.saved = true;
    return this.afs.collection<Data>('data').add(param);

  }

}
