<mat-dialog-content style="padding:1rem">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Change Password
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="card-text">
                <div class="alert alert-danger" role="alert" *ngIf="errorMsg">
                    {{errorMsg}}
                </div>
                <div class="alert alert-success" role="alert" *ngIf="successMsg">
                    {{successMsg}}
                </div>

                <div class="form-group">
                    <label for="CurrentPassword">Current Password</label>
                    <input type="password" class="form-control" id="CurrentPassword" [(ngModel)]="currentPassword"
                        placeholder="Password">
                </div>
                <div class="form-group">
                    <label for="newPassword">New Password</label>
                    <input type="password" class="form-control" id="newPassword" [(ngModel)]="newPassword"
                        aria-describedby="passwordHelp" placeholder="New Password">
                    <small id="passwordHelp" class="form-text text-muted">8-20 ตัวหนังสือ</small>
                </div>
                <div class="form-group">
                    <label for="confirmPassword">Confirm Password</label>
                    <input type="password" class="form-control" id="confirmPassword" [(ngModel)]="confirmPassword"
                        placeholder="Confirm Password">
                </div>
            </div>
            <button class="btn btn-primary text-right" (click)="save()">save</button>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Update Profile
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="card-text">
                <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                    <div class="alert alert-danger" role="alert" *ngIf="error_profile">
                        {{error_profile}}
                    </div>
                    <div class="alert alert-info" role="alert" *ngIf="info_profile">
                        {{info_profile}}
                    </div>
                    <div class="alert alert-success" role="alert" *ngIf="success_profile">
                        {{success_profile}}
                    </div>
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>ชื่อคลินิก</mat-label>
                        <input [formControl]="clinicName" [errorStateMatcher]="matcher" matInput>

                        <mat-error *ngIf="clinicName.hasError('required')">
                            โปรดกรอก
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>จังหวัด</mat-label>
                        <input [formControl]="city" [errorStateMatcher]="matcher" matInput>

                        <mat-error *ngIf="city.hasError('required')">
                            โปรดกรอก
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>อำเภอ</mat-label>
                        <input [formControl]="area" [errorStateMatcher]="matcher" matInput>
                        <mat-error *ngIf="area.hasError('required')">
                            โปรดกรอก
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>เบอร์โทรศัพท์</mat-label>
                        <input [formControl]="telphone" [errorStateMatcher]="matcher" matInput>

                        <mat-error *ngIf="telphone.hasError('required')">
                            โปรดกรอก
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Line ID</mat-label>
                        <input [formControl]="LineId" [errorStateMatcher]="matcher" matInput>

                        <mat-error *ngIf="LineId.hasError('required')">
                            โปรดกรอก
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>เวปไซด์ (ถ้ามี)</mat-label>
                        <input [formControl]="website" [errorStateMatcher]="matcher" matInput>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>ทันตแพทย์เจ้าของคลินิก</mat-label>
                        <input [formControl]="doctorName" [errorStateMatcher]="matcher" matInput>

                        <mat-error *ngIf="doctorName.hasError('required')">
                            โปรดกรอก
                        </mat-error>
                    </mat-form-field>

                    <p>
                        <button type="submit" class="btn btn-primary">Save</button>
                    </p>

                </form>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Doctor List
                </mat-panel-title>
                <mat-panel-description>
                    รายชื่อหมอ
                </mat-panel-description>
            </mat-expansion-panel-header>
            <app-doctor-list></app-doctor-list>
        </mat-expansion-panel>
    </mat-accordion>
</mat-dialog-content>