import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

export class Summery {
  cleanDocs: number;
  numberOfDocs: number;
  standardizeDocs: number;
  watchOutDocs: number;
}

@Injectable({
  providedIn: 'root'
})
export class SummeryService {

  constructor(private afs: AngularFirestore) { }

  getSummery(url) {
    return this.afs.doc<Summery>('summery-dental/' + url).valueChanges();
  }

}

