import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ClinicService, ClinicInfo } from '../../service/clinic.service';
import { AuthService } from '../../service/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DoctorEditDialogComponent } from './doctor-edit-dialog/doctor-edit-dialog.component';
import { Subscription } from 'rxjs';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
  { position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na' },
  { position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg' },
  { position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al' },
  { position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si' },
  { position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P' },
  { position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S' },
  { position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl' },
  { position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar' },
  { position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K' },
  { position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca' },
];

@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.scss']
})
export class DoctorListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'button'];
  dataSource = new MatTableDataSource<String>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private auth: AngularFireAuth,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private clinicService: ClinicService,
    private authService: AuthService) { }

  private clinicInfo: ClinicInfo;
  private clinicInfoSub: Subscription;
  private doctorList: string[];
  ngOnInit(): void {
    this.clinicInfo = this.clinicService.getClinicInfo();
    this.clinicInfoSub = this.clinicService.requestClinicInfo(this.clinicInfo.url).subscribe((value) => {
      this.dataSource.data = value.doctorList;
      this.doctorList = value.doctorList;
      this.dataSource.paginator = this.paginator;
    })
    this.dataSource.paginator = this.paginator;
  }


  openEditDialog(element?) {
    const dialogRef = this.dialog.open(DoctorEditDialogComponent, {
      data: element, width: "1200px",
    });
    dialogRef.afterClosed().subscribe(async dialogResult => {
      if (dialogResult) {
        await this.clinicService.addDoctor(this.clinicInfo.url, dialogResult)
        //this.scheduleService.cancelSchedule(element.id, this.clinicInfo.url).then(() => this.snackBar.open(`ยกเลิกนัด ${element.refId} แล้ว`, '', { duration: 3000, }));
      }
      console.log(dialogResult)
    });
  }

  async delete(element) {
    await this.clinicService.removeDoctor(this.clinicInfo.url, element)

  }
}
