<div class="center">
    <div class="container-fluid title-bar">
        <h1>จองคิวปรึกษา Online</h1>
        <h2>(Telemedicine)</h2>
        <p>การปรึกษา Online ขึ้นกับความพร้อม และศักยภาพของแต่ละโรงพยาบาล</p>
        <p>หากไม่ได้รับการติดต่อกลับเจ้าหน้าที่ ท่านสามารถติดต่อ รพ.ใกล้บ้านเพื่อรับการตรวจได้</p>
    </div>
    <div class="container-fluid">
        <div class="result">
            เจ้าหน้าที่จะติดต่อกลับ ตามช่วงเวลาที่ท่านระบุ
        </div>
        <br />
        <div class="datetime">
            {{appointDay}} ที่ {{appointDate}} {{appointMonth}} {{appointYear}}
        </div>
        <div class="datetime">
            เวลา {{appointTime}}
        </div>
        <div class="blueline">
            {{appointHos}}
        </div>
        <div class="blueline">
            Reference No. {{appointRef}}
        </div>
        <br />
        <p class="detail">
            เวปไซด์นี้ เป็นเวปไซด์ไม่แสวงหากำไร <br />
            มีวัตถุประสงค์เพื่อคัดกรองประชาชนที่มีความเสี่ยง แล้วส่งข้อมูลให้กับ
            รพ.ที่รับผิดชอบดูแลในเขตพื้นที่ที่ท่านเลือก<br />
            <br />
            ดังนั้นจึงต้องอาศัยความร่วมมือ จาก รพ.ในเขตพื้นที่ที่ท่านอาศัยอยู่<br />
            ทั้งนี้อาจมีข้อจำกัดด้านกำลังคน และ การบริการของแต่ละ รพ.<br />
            <br />
            หากไม่ได้รับการติดต่อกลับเจ้าหน้าที่<br />
            ท่านสามารถติดต่อ รพ.ใกล้บ้านเพื่อรับการตรวจได้
        </p>
        <button type="button" [routerLink]="['/']" class="btn btn-outline-warning btn-lg">กลับหน้าหลัก</button>
    </div>
    <br />
</div>