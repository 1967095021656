<div class="center">
    <div class="title-bar">
        <h1>Bulk Register</h1>
        <h2>Platform สำหรับคลินิกทันตกรรม</h2>
    </div>
    <div class="body" style="height: 100%;min-height: 100%;">
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <div class="alert alert-danger" role="alert" *ngIf="error">
                {{this.error}}
            </div>
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Data</mat-label>
                <input [formControl]="data" [errorStateMatcher]="matcher" matInput required>
            </mat-form-field>


            <p>
                <button type="submit" class="btn btn-primary btn-lg">ลงทะเบียน</button>
            </p>

        </form>
    </div>
</div>