<h1 mat-dialog-title>
    เพิ่มหมอ
</h1>

<div mat-dialog-content>
    <form class="example-form">
        <mat-form-field class="example-full-width">
            <mat-label>ชื่อแพทย์</mat-label>
            <input matInput [formControl]="name" placeholder="ชื่อแพทย์">
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onDismiss()" cdkFocusInitial>Cancel</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Save</button>
</div>