<div class="center">
    <div class="container-fluid title-bar">
        <h1>{{this.clinicInfo.name}} </h1>
        <h2>{{this.clinicInfo.city}}</h2>
    </div>
    <div class="body container-fluid">
        <p>ตามมาตรการควบคุมการแพร่กระจายของเชื้อ COVID-19</p>
        <div style="padding-top: 4rem;">
            <h3>กรุณากรอกแบบคัดกรองก่อนทำการรักษา</h3>
            <p>หรือ</p>
            <h3>ทำการจองคิวล่วงหน้าก่อนมาคลินิก</h3>
        </div>
        <div class="row justify-content-center" style="padding-top: 5rem;">
            <div class="col-12">
                <button type="button" (click)="goToQuestion()" class="btn btn-primary ">ทำแบบคัดกรอง<br />
                    ก่อนการรักษา</button>
                <span class="mr-5"></span>
                <button type="button" routerLink="./schedule" class="btn btn-warning">จองคิว online<br />
                    ก่อนมาคลินิก</button>
            </div>

        </div>

    </div>