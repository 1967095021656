// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDiM9XRZiC5XK7RCTjER2vOdeiIM0Apai8",
    authDomain: "covid-5d543.firebaseapp.com",
    databaseURL: "https://covid-5d543.firebaseio.com",
    projectId: "covid-5d543",
    storageBucket: "covid-5d543.appspot.com",
    messagingSenderId: "929647595906",
    appId: "1:929647595906:web:5532d78e67810f49c67f9b",
    measurementId: "G-1DVZMLM3NB"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
