import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartComponent } from './component/start/start.component';
import { SecondComponent } from './component/second/second.component';
import { ResultComponent } from './component/result/result.component';
import { redirectUnauthorizedTo, canActivate, AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { TelemedicComponent } from './component/telemedic/telemedic.component';
import { TelemedicResultComponent } from './component/telemedic-result/telemedic-result.component';
import { LayoutComponent } from './component/layout/layout.component';
import { ClinicComponent } from './clinic/clinic.component';
import { StartClinicComponent } from './clinic/component/start/start-clinic.component';
import { ScheduleComponent } from './clinic/component/schedule/schedule.component';
import { LoginComponent } from './clinic/component/login/login.component';
import { SignupComponent } from './clinic/component/signup/signup.component';
import { AuthComponent } from './clinic/component/auth/auth.component';
import { ScheduleListComponent } from './clinic/component/auth/schedule-list/schedule-list.component';
import { ScheduleResultComponent } from './clinic/component/schedule-result/schedule-result.component';
import { SignupResultComponent } from './clinic/component/signup/signup-result/signup-result.component';
import { SignupJsonComponent } from './clinic/component/signup-json/signup-json.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);

const routes: Routes = [
  {
    path: 'home',
    component: AuthComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      { path: '', component: ScheduleListComponent }
    ]
  },
  {
    path: '', component: LayoutComponent,
    children: [
      { path: '', component: StartComponent },
      { path: 'question', component: SecondComponent },
      { path: 'result', component: ResultComponent },
      { path: 'telemedic', component: TelemedicComponent },
      { path: 'telemedic-result', component: TelemedicResultComponent },
      { path: 'login', component: LoginComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'signup-result', component: SignupResultComponent },
      { path: 'signup-json', component: SignupJsonComponent },
      {
        path: 'dental/:clinicUrl',
        component: ClinicComponent,
        children: [
          { path: '', component: StartClinicComponent },
          { path: 'question', component: SecondComponent },
          { path: 'result', component: ResultComponent },
          { path: 'schedule', component: ScheduleComponent },
          { path: 'schedule-result', component: ScheduleResultComponent },
          { path: '**', redirectTo: '', component: StartClinicComponent },
        ]
      },


      { path: '**', redirectTo: '/', pathMatch: 'full' },
    ]
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
