import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ClinicService, ClinicInfo } from '../../service/clinic.service';
import { AuthService } from '../../service/auth.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  public currentPassword: string;
  public newPassword: string;
  public confirmPassword: string;
  public errorMsg: string;
  public successMsg: string;


  public error_profile: string;
  public success_profile: string;
  public info_profile: string;
  matcher = new MyErrorStateMatcher();
  profileForm = new FormGroup({
    clinicName: new FormControl(null, [Validators.required,]),
    area: new FormControl(null, [Validators.required,]),
    city: new FormControl(null, [Validators.required,]),
    telphone: new FormControl(null, [Validators.required,]),
    LineId: new FormControl(null, [Validators.required,]),
    website: new FormControl(),
    doctorName: new FormControl(null, [Validators.required,]),
  });
  public error: string;
  get clinicName() { return this.profileForm.get('clinicName'); }
  get area() { return this.profileForm.get('area'); }
  get city() { return this.profileForm.get('city'); }
  get telphone() { return this.profileForm.get('telphone'); }
  get LineId() { return this.profileForm.get('LineId'); }
  get website() { return this.profileForm.get('website'); }
  get doctorName() { return this.profileForm.get('doctorName'); }
  public clinicInfo: ClinicInfo;

  constructor(private auth: AngularFireAuth, private snackBar: MatSnackBar, private clinicService: ClinicService, private authService: AuthService) { }

  ngOnInit(): void {
    this.clinicInfo = this.clinicService.getClinicInfo();
    this.clinicName.setValue(this.clinicInfo.name);
    this.area.setValue(this.clinicInfo.area);
    this.city.setValue(this.clinicInfo.city);
    this.telphone.setValue(this.clinicInfo.telphone);
    this.LineId.setValue(this.clinicInfo.LineId);
    this.website.setValue(this.clinicInfo.website);
    this.doctorName.setValue(this.clinicInfo.doctorName);
  }
  async onSubmit() {
    if (this.profileForm.invalid == false) {
      this.info_profile = "Saving";
      this.snackBar.open('Saving...', '', {
        duration: 5000
      });
      //update info
      const clinicInfo: ClinicInfo = new ClinicInfo();
      clinicInfo.name = this.clinicName.value;
      clinicInfo.area = this.area.value;
      clinicInfo.city = this.city.value;
      clinicInfo.telphone = this.telphone.value;
      clinicInfo.LineId = this.LineId.value;
      clinicInfo.website = this.website.value;
      clinicInfo.doctorName = this.doctorName.value;
      clinicInfo.url = this.clinicInfo.url;
      this.clinicService.createClinicInfo(clinicInfo).then(() => {
        console.log("success");
        this.info_profile = "";
        this.success_profile = "Update Profile Success"
        this.snackBar.open('Update Profile Success', '', {
          duration: 3000
        });
      }).catch((error) => {
        console.error(error);
        this.info_profile = ""
        this.error_profile = "Internal Error"
        this.snackBar.open('Internal Error', '', {
          duration: 3000
        });
        this.error = "Internal Error";
      })

    }
  }

  save() {
    this.errorMsg = null;
    if (!this.currentPassword || this.currentPassword.length == 0) {
      this.errorMsg = "โปรดใส่รหัสผ่านเดิมที่ถูกต้อง";
      return;
    }

    if (!this.newPassword || this.newPassword.length < 8 || this.newPassword.length > 20) {
      this.errorMsg = "โปรดใส่รหัสผ่านใหม่ที่ถูกต้อง";
      return;
    }

    if (this.newPassword !== this.confirmPassword) {
      this.errorMsg = "ยืนยันรหัสผ่านใหม่ไม่ตรงกับรหัสใหม่";
      return;
    }

    const sub = this.auth.user.subscribe((user) => {
      sub.unsubscribe();
      if (user) {
        console.dir(user);
        console.dir(this.currentPassword);
        const credentials = firebase.auth.EmailAuthProvider.credential(user.email, this.currentPassword);

        user.reauthenticateWithCredential(credentials).then(() => {
          user.updatePassword(this.newPassword).then(() => this.successMsg = "เปลี่ยนรหัสผ่านสำเร็จ")
        }).catch((error) => this.errorMsg = "โปรดใส่รหัสผ่านเดิมที่ถูกต้อง")
      }


    })

  }
}
